import React, { useState } from 'react';
import { Menu, Button, Drawer, Space } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { getJwtFromStorage } from '../../../../helpers/localstorage';
import { basePath } from '../../../../helpers/urlHelper';
import { getItem } from '../../../../helpers/menuHelper';
import { getUserId } from '../../../../helpers/jwt';
import FamilyPicker from './FamilyPicker';
import useLogout from '../../../../hooks/useLogout';
import { useNavigate } from 'react-router';
import { DarkModeSwitch } from '../../../DarkModeSwitch';
import { useSignalR } from '../../../../contexts/SignalRContext';

export default function MenuDrawer() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [handleLogout] = useLogout();
  const { DisplayStatusBadge } = useSignalR();

  const drawerTitle = () => {
    if (getUserId()) {
      return (
        <>
          <FamilyPicker />
        </>
      );
    }
    return (<></>);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onMenuClick = (e) => {
    onClose();
    switch (e.key) {
    case "list":
      navigate(`${basePath()}/list`);
      break;
    case "ingredients":
      navigate(`${basePath()}/ingredients/list`);
      break;
    case "recipes":
      navigate(`${basePath()}/recipes/list`);
      break;
    case "family":
      navigate(`${basePath()}/family/general`);
      break;
    case "admin":
      navigate(`${basePath()}/admin/configuration`);
      break;
    case "user-profile":
      navigate(`${basePath()}/userprofile`);
      break;
    case "logout":
      handleLogout();
      break;
    default:
      navigate(`${basePath()}/login`);
    }
  };

  const items = () => {
    if (getJwtFromStorage()) {
      return [
        getItem(<Space>
          List
          <DisplayStatusBadge />
        </Space>, 'list'),
        getItem('Ingredients', 'ingredients'),
        getItem('Recipes', 'recipes'),
        // getItem('Family', 'family'),
        getItem('User Profile', 'user-profile'),
        // getItem('Admin', 'admin'),
        getItem('Logout', 'logout'),
      ];
    }
    else {
      return [
        getItem('Log In / Sign Up', 'login'),
      ];
    }
  };

  return (
    <>
      <Button type="dashed" icon={<MenuOutlined />} onClick={showDrawer} />
      <Drawer
        title={drawerTitle()}
        placement="left"
        closable={true}
        onClose={onClose}
        open={open}
        className="mobile-menu-drawer"
        extra={<DarkModeSwitch />}
      >
        <Menu
          mode="inline"
          items={items()}
          onClick={onMenuClick}

        />
      </Drawer>
    </>
  );
};