import 'react-quill/dist/quill.snow.css';
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, InputNumber, Alert, Divider, AutoComplete, Tag, Row, Col, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/loading';
import { updateRecipe, getRecipe, getUnitsOfMeasure } from '../../api/nomnom/Family/recipe';
import { getFamilyId } from '../../helpers/localstorage';
import EditableTagGroup from '../../components/tags/EditableTagGroup';
import { search as searchIngredient } from '../../api/nomnom/Family/ingredient';
import ReactQuill from 'react-quill';
import { basePath } from '../../helpers/urlHelper';
import TextArea from '../../components/Form/TextArea';
import Images from './Images';

export default function Edit({ recipeId }) {
  const params = useParams();
  const [data, setData] = useState({});
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const [measurementOptions, setMeasurementOptions] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { Option } = Select;

  useEffect(() => {
    setLoading(true);
    getUnitsOfMeasure(getFamilyId()).then(resp => {
      setMeasurementOptions(
        resp.map(measurement => { return (<Option value={measurement.name}>{measurement.name}</Option>); })
      );
    });
    getRecipe(getFamilyId(), recipeId ?? params.recipeId).then((resp) => { setData(resp); setLoading(false); });
  }, [params.recipeId, recipeId]);

  const onFinish = (values) => {
    setError();
    if (values.tags) {
      values.tags = values.tags.map(item => {
        return { ...item, recipeId: recipeId ?? params.recipeId };
      });
    }
    setLoading(true);
    updateRecipe(getFamilyId(), values).then((response) => {
      setLoading(false);
      if (response.errors) {
        setError("There was an error saving your request");

      }
      else {
        navigate(`${basePath()}/recipes/list`);
      }
    });
  };

  const autocompleteIngredient = (ingredient) => ({
    value: ingredient.name,
    ingredient: ingredient,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span>
          {ingredient.name}
        </span>
        {ingredient.tags && <span>{ingredient.tags.map(tag => <Tag>{tag.name}</Tag>)}</span>}
      </div>
    ),
  });

  const onSearchIngredient = (searchText) => {
    searchIngredient(getFamilyId(), 1, 5, searchText).then(resp => {
      setIngredientOptions(
        resp.items.map(ingredient => { return autocompleteIngredient(ingredient); })
      );
    });
  };

  const onSelectIngredient = (data, option) => {
    let ingredients = form.getFieldValue("ingredients");
    ingredients[ingredients.length - 1] = { ...option.ingredient, id: 0, ingredientId: option.ingredient.id };
    form.setFieldsValue(ingredients);
  };

  const getModules = () => {
    return {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
      ],
    };
  };

  return (
    <Loading isLoading={loading}>
      {data.tags && <EditableTagGroup isEditable entityId={data.id} entityType="recipe" tags={data.tags} />}
      <Form
        name="basic"
        initialValues={data}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Images
          recipeId={recipeId ?? params.recipeId}
        />
        <Form.Item
          name="id"
          hidden
        />
        <Form.Item
          name="familyId"
          hidden
        />
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add a name!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 3 }}>
            <Form.Item
              label="Servings"
              name="servings"
            >
              <Input /*style={{ minWidth: "75px" }}*/ defaultValue={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 13 }}>
            <Form.Item
              label="Description"
              name="description"
            >
              <TextArea defaultValue="empty" />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Ingredients</Divider>
        <Form.List name="ingredients"
          noStyle>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Form.Item
                  key={key}
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.ingredients[key]?.id !== currentValues.ingredients[key]?.id}
                >
                  {({ getFieldValue }) => {
                    return getFieldValue('ingredients')[key]?.ingredientId > 0 ? (
                      <Row key={key} gutter={{ xs: 8, sm: 8, md: 10, lg: 10, xl: 10 }} >
                        <Form.Item
                          name={[name, "id"]}
                          hidden={true} />
                        <Form.Item
                          name={[name, "ingredientId"]}
                          hidden={true} />
                        <Form.Item
                          name={[name, "familyId"]}
                          hidden={true} />
                        <Form.Item
                          name={[name, "tags"]}
                          hidden={true} />
                        <Form.Item
                          name={[name, "name"]}
                          hidden={true}
                        />
                        <Col xs={{ span: 10 }} md={{ span: 14 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                          {form.getFieldValue('ingredients')[name].name}
                        </Col>
                        <Col xs={{ span: 5 }} md={{ span: 3 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                          <Form.Item
                            noStyle
                            required
                            initialValue={1}
                            name={[name, "amount"]}
                            {...restField}
                          >
                            <InputNumber
                              min="0.25"
                              step="0.25"
                              style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 8 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 3 }}>
                          <Form.Item
                            name={[name, "unitOfMeasure"]}
                          >
                            <Select
                              showSearch
                              allowClear
                              placeholder="Unit"
                              style={{ width: "100%" }}
                            >
                              {measurementOptions}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={1} ><MinusCircleOutlined title="Remove Ingredient" onClick={() => remove(name)} /></Col>
                      </Row>
                    ) : <Form.Item>
                      <AutoComplete
                        options={ingredientOptions}
                        style={{ width: 200 }}
                        onSelect={onSelectIngredient}
                        onSearch={onSearchIngredient}
                        placeholder="Search Ingredients"
                        autoFocus={true}
                      />
                    </Form.Item>;
                  }
                  }
                </Form.Item>
              ))}
              <Form.Item>
                <Row>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 11 }}>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Ingredient
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Divider orientation="left">Directions</Divider>
        <Form.Item
          name="directions"
          nostyle
        >
          <ReactQuill
            theme="snow"
            modules={getModules()}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
        {error && <Alert message={error} type="error" />}
      </Form>
    </Loading >
  );
};