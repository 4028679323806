import React, { useState } from "react";
import { Modal, Button, Form } from 'antd';
import { useNavigate } from "react-router";
import { addTag } from "../../api/nomnom/Family/ingredient";
import { PlusOutlined } from '@ant-design/icons';
import { getFamilyId } from "../../helpers/localstorage";
import { AutoFocusInput } from "../Form/AutoFocusInput";

export function CreateIngredientTagModal({ ingredientId }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const onFinish = (values) => {
    setLoading(true);
    addTag(getFamilyId(), ingredientId, values).then(() => {
      navigate(0);
      setOpen(false);
    });
  };

  return (
    <>
      <Button
        title="Add Tag"
        icon={<PlusOutlined />}
        onClick={showModal} />

      <Modal
        open={open}
        title="Add Tag"
        destroyOnClose={true}
        confirmLoading={loading}
        onCancel={handleCancel}
        onOk={() => {
          form.validateFields().then((values) => {
            onFinish(values);
          });
        }}
      >
        <Form
          form={form}
          name="createTag"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Name is required',
              },
            ]}
          >
            <AutoFocusInput />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};