import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Row } from 'antd';
import Loading from '../../../components/loading';
import { getTagCategory } from "../../../api/nomnom/Family/tagCategories";
import { deleteTag } from "../../../api/nomnom/Family/tag";
import { getFamilyId } from "../../../helpers/localstorage";
import { MinusCircleOutlined } from '@ant-design/icons';

export default function TagCategory() {
  let params = useParams();
  let [data, setData] = useState();
  let [isLoading, setLoading] = useState(true);
  useEffect(() => {
    getTagCategory(getFamilyId(), params.tagCategoryId).then((resp) => { setData(resp); setLoading(false); });

  }, [params.tagCategoryId, setData, setLoading]);

  const remove = (id) => {
    deleteTag(getFamilyId(), id)
      .then(() => getTagCategory(getFamilyId(), params.tagCategoryId).then((resp) => { setData(resp); setLoading(false); }));
  };

  return (
    <Loading isLoading={isLoading}>
      {data && <Card title={<>{data.name}</>}>
        {data && data.tags && data.tags.map(tag => (
          <Card.Grid key={tag.id} hoverable={false} style={{ width: '25%' }}>
            <Row>
              <Col span={20}>
                {tag.name}
              </Col>
              <Col>
                <MinusCircleOutlined onClick={() => remove(tag.id)} />
              </Col>
            </Row>
          </Card.Grid>
        ))}
      </Card>}
    </Loading>
  );
};