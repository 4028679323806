import axios from 'axios';
import { clearLocalStorage, getJwtFromStorage } from '../../helpers/localstorage';
import { NOMNOM_URL } from '../../config';

export function get(url, data) {
  return axios
    .get(`${NOMNOM_URL}${url}`, {
      params: data,
      headers: {
        Authorization: `Bearer ${getJwtFromStorage()}`
      }
    })
    .then(handleResponse);
}

export function post(url, body) {
  return axios
    .post(`${NOMNOM_URL}${url}`, body, {
      headers: {
        Authorization: `Bearer ${getJwtFromStorage()}`
      }
    })
    .then(handleResponse);
}


export function postPagination(url, body) {
  return axios
    .post(`${NOMNOM_URL}${url}`, body, {
      headers: {
        Authorization: `Bearer ${getJwtFromStorage()}`,
        AccessControlAllowMethods: '*'
      }
    })
    .then((response) => {
      return { data: response.data, pagination: JSON.parse(response.headers['x-pagination']) };
    });
}

export function put(url, body) {
  return axios
    .put(`${NOMNOM_URL}${url}`, body, {
      headers: {
        Authorization: `Bearer ${getJwtFromStorage()}`
      }
    })
    .then(handleResponse);
}

export function del(url) {
  return axios
    .delete(`${NOMNOM_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${getJwtFromStorage()}`,
        AccessControlAllowMethods: '*'
      }
    })
    .then(handleResponse);
}

const handleResponse = response => response.data;

const interceptResponse = response => {
  if (response.code) {
    const error = response.response;

    if (error.status === 400) {
      return { data: error.data };
    }
    if (error.status === 401) {
      clearLocalStorage();
      window.location.href = '/login';
    }
  }
  response.isSuccess = true;
  return response;
};

axios.interceptors.response.use(response => {
  return interceptResponse(response);
}, error => {
  return interceptResponse(error);
});