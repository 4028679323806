import React, { useState, useEffect } from "react";
import { Table } from 'antd';
// import { updateFamily } from "../../../api/nomnom/Family/family";

export default function List({ data }) {
  const [_data, setData] = useState(undefined);

  useEffect(() => {
    if (!_data && data && data.length)
      setData(data);
  }, [_data, setData, data]);

  // const onChange = (family) => {
  //   updateFamily({ ...family, isPrivate: !family.isPrivate })
  //     .then((response) => {
  //       const families = _data.map((mappedFamily) => {
  //         if (mappedFamily.id === response.id) {
  //           return { ...mappedFamily, isPrivate: response.isPrivate };
  //         }
  //         return mappedFamily;
  //       });

  //       setData(families);
  //     });
  // };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: row => row.name
    }
  ];

  return (
    <>
      <Table dataSource={_data} columns={columns} />
    </>
  );
};