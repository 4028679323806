import React, { useState, useRef, useEffect } from 'react';
import { Button, Form, Input, Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import { createTagCategory } from '../../../api/nomnom/Family/tagCategories';
import { getFamilyId } from '../../../helpers/localstorage';

export default function Add() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus({
      cursor: 'end',
    });
  }, []);

  const onFinish = (values) => {
    setError();
    setLoading(true);
    createTagCategory(getFamilyId(), values).then((response) => {
      setLoading(false);
      if (!response.status) {
        navigate("../list");
      }
      else {
        setError(response.data);
      }
    });
  };

  return (
    <>
      <h2>Create Tag Category</h2>
      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please add a name!' }]}

        >
          <Input ref={inputRef} />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
        {error && <Alert message={error} type="error" />}
      </Form>
    </>
  );
};