import React from "react";
import { Table } from 'antd';
import { getFamily } from "../../../helpers/localstorage";

export default function List({ data }) {
  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: row => row.name
    },
    {
      title: '',
      key: 'id',
      render: row => row.id === getFamily().id ? "check" : "false"
    }
  ];

  return (
    <>
      <Table dataSource={data} columns={columns} />
    </>
  );
};