import './FamilyDropdown.css';
import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Button } from 'antd';
import { getFamily, setFamily } from '../../../../helpers/localstorage';
import { useFetchUserFamilies } from '../../../../api/nomnom/UserProfile/useFetchUserFamilies';
import { useNavigate } from 'react-router';
import CreateFamilyModal from '../../../../pages/UserProfile/CreateFamilyModal';
import useTheme from '../../../../hooks/useTheme';

export default function FamilyDropDown() {
  const [darkMode] = useTheme();
  const families = useFetchUserFamilies();
  const navigate = useNavigate();
  const onClick = (family) => {
    setFamily(family);
    navigate(0);
  };

  let items = families?.data
    ?.filter((family) => family.id !== getFamily().id)
    .map((family) => {
      return {
        label: <Button type="link" size="small" className={`${darkMode ? "dark-family" : ""}`} onClick={() => onClick(family)}>{family.name}</Button>,
        key: family.id
      };
    }) ?? [];

  items.push({
    label: <CreateFamilyModal className={`${darkMode ? "dark-family" : ""}`} />,
    key: 0
  });

  return (
    <Dropdown menu={{ items }}>
      <div
        className={`header-user-account`}
        style={{ cursor: "pointer" }}
      >
        <Space>
          {getFamily().name}
          <DownOutlined />
        </Space>
      </div>
    </Dropdown >);
}