import React from "react";
import { Space, Spin } from 'antd';
import './Loading.css';

export default function Loading({ isLoading, children }) {
  if (isLoading) {
    return (
      <div className="loading-container">
        <Space size="middle">
          <Spin size="large" />
        </Space>
      </div>
    );
  }
  return (children);
};