import './List.css';
import React, { useState, useEffect } from "react";
import { Tag, Row, Col, Space, Card, Popconfirm, Button, Form, Layout, Menu } from 'antd';
import { search as searchRecipes, deleteRecipe, getTags } from '../../../api/nomnom/Family/recipe';
import { getFamilyId } from "../../../helpers/localstorage";
import Loading from '../../../components/loading';
import { CloudOutlined, EditOutlined, DeleteOutlined, PlusOutlined, CameraOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import parse from "html-react-parser";
import { basePath } from '../../../helpers/urlHelper';
import RecipeFront from './RecipeFront';
import { isMobile } from 'react-device-detect';
import Tooltip from 'antd/es/tooltip';
import { AutoFocusInput } from '../../../components/Form/AutoFocusInput';

export const DisplayBrowser = React.memo(() => {
  const { Sider, Content } = Layout;
  const navigate = useNavigate();
  const [data, setData] = useState({
    items: [],
    currentPage: 1,
    pageSize: 20,
    hasNext: true,
    isLoading: true,
    isDeleteLoading: false
  });
  const [tags, setTags] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [search, setSearch] = useState();
  const [activeTabKeys, setActiveTabKeys] = useState([]);
  const [activeTag, setActiveTag] = useState(null);
  const loadData = (currentPage, pageSize, search, append = true) => {
    searchRecipes(getFamilyId(), currentPage, pageSize, search, activeTag).then((resp) => {
      if (append) {
        setData({ ...resp, items: [...data.items, ...resp.items] });
      }
      else if (resp) {
        setData(resp);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData(1, data.pageSize, null, false);
    getTags(getFamilyId()).then((resp) => {
      setTags(resp);
    });
    // eslint-disable-next-line
  }, [activeTag]);

  const onDelete = (recipeId) => {
    setDeleteLoading(true);
    deleteRecipe(getFamilyId(), recipeId).then(() => {
      loadData(data.currentPage, data.pageSize, null, false);
      setDeleteLoading();
    });
  };

  const onSearchChange = (e) => {
    setSearch(e.target.value);
    loadData(1, 20, e.target.value ?? null, false);
  };

  const onAdd = (values) => {
    navigate(`${basePath()}/recipes/add?${search ? `name=${search}` : ''}`);
  };


  const tabList = [
    {
      key: 'front',
      tab: 'Ingredients',
    },
    {
      key: 'back',
      tab: 'Directions',
    },
  ];

  const contentList = (item) => {
    switch (getActiveTab(item.id)) {
    case 'front': return (
      <RecipeFront recipe={item} />
    );
    case 'back': return (<>
      <div>
        {parse(item?.directions ?? "")}
      </div>
    </>);
    default: return (<></>);
    }
  };

  const getActiveTab = id => {
    if (activeTabKeys.length > 0 && activeTabKeys.filter(a => a.id === id).length) {
      return activeTabKeys.filter(a => a.id === id)[0].key ?? "front";
    }
    return "front";
  };

  const onTabChange = (id, key) => {
    setActiveTabKeys([...(activeTabKeys.filter(a => a.id !== id)), { id, key }]);
  };

  const setActiveTagAndLoad = (tag) => {
    setActiveTag(tag);
  };

  return (
    <Loading isLoading={isLoading}>
      <Layout>
        <Sider>
          <Menu
            mode="vertical"
            defaultSelectedKeys={"all"}
            style={{ height: '100%', borderRight: 0 }}>
            <Menu.Item key="all" onClick={() => setActiveTagAndLoad()}>
                All
            </Menu.Item>
            {tags && tags.map(tag =>
              (<Menu.Item key={tag} onClick={() => setActiveTagAndLoad(tag)}>
                {tag}
              </Menu.Item>))
            }
          </Menu>
        </Sider>
        <Content>
          <Form
            name="search"
            onFinish={onAdd}
            autoComplete="off"
          >
            <Row style={{ paddingBottom: "10px" }}>
              <Col offset={1} lg={{ span: 20 }} xs={{ span: 18 }} >
                <AutoFocusInput
                  style={{
                    width: "100%",
                  }}
                  onChange={onSearchChange}
                />
              </Col>
              <Col lg={{ span: 3 }} xs={{ span: 5 }}>
                <Space>
                  <Tooltip title="Create new recipe" >
                    <Button style={{ marginLeft: "1em" }} type="primary" htmlType="submit" icon={<PlusOutlined />} />
                  </Tooltip>
                  <Tooltip title="Import recipe from web">
                    <Button disabled icon={<CloudOutlined />} />
                  </Tooltip>
                  <Tooltip title="Import recipe from picture">
                    <Button disabled icon={<CameraOutlined />} />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
          <InfiniteScroll
            style={{ overflow: 'none' }}
            dataLength={data.items ? data.items.length : 20} //This is important field to render the next data
            next={() => loadData(data.currentPage + 1, data.pageSize)}
            hasMore={data.hasNext}
            loader={<h4>Loading...</h4>}>
            <Row gutter={[16, 24]}>
              {data.items && data.items.map(item => (
                <Col offset={1} span={23} key={`col-${item.id}`}>
                  <Card
                    title={<><>{item.name}</> {item.tags.map(tag => <Tag>{tag.name}</Tag>)}</>}
                    extra={!isMobile && <>Servings: {item.servings}</>}
                    alt={item.name}
                    tabList={item.directions.length ? tabList : null}
                    activeTabKey={getActiveTab(item.id)}
                    className="recipe-card"
                    onTabChange={key => {
                      onTabChange(item.id, key);
                    }}
                    actions={[
                      <Link to={`../${item.id}`}>
                        <EditOutlined key={`edit-${item.id}`} />
                      </Link>,
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => onDelete(item.id)}
                        isLoading={isDeleteLoading}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined key={`delete-${item.id}`} />
                      </Popconfirm>,
                    ]}>
                    {contentList(item)}
                  </Card>
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
        </Content>
      </Layout>
    </Loading>
  );
});