import React, { useState, useEffect } from "react";
import { BrowserView, MobileView } from 'react-device-detect';
import { useNavigate, useParams } from "react-router";
import Loading from "../../../components/loading";
import { getShoppingListIngredient, updateShoppingListIngredient } from "../../../api/nomnom/Family/shoppingListIngredient";
import { getFamilyId, getShoppingListId } from "../../../helpers/localstorage";
import { DisplayBrowser } from "./DisplayBrowser";
import { DisplayMobile } from "./DisplayMobile";
import { getTagCategories } from "../../../api/nomnom/Family/tagCategories";

const ShoppingListIngredient = React.memo(() => {
  let params = useParams();
  let [data, setData] = useState({});
  let [tags, setTags] = useState([]);
  let [isLoading, setLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    getTagCategories(getFamilyId(), 1, 100).then(resp => setTags(resp.items[0].tags));
    getShoppingListIngredient(getFamilyId(), getShoppingListId(), params.shoppingListIngredientId)
      .then((resp) => {
        setData(resp);
        setLoading(false);
      });
  }, [params.shoppingListIngredientId]);

  const save = (shoppingListIngredient) => {
    updateShoppingListIngredient(getFamilyId(), getShoppingListId(), shoppingListIngredient).then(response => navigate("../"));
  };

  return (
    <Loading isLoading={isLoading}>
      <BrowserView>
        <DisplayBrowser
          shoppingListIngredient={data}
          updateShoppingListIngredient={save}
          tagOptions={tags} />
      </BrowserView>
      <MobileView>
        <DisplayMobile
          shoppingListIngredient={data}
          updateShoppingListIngredient={save}
          tagOptions={tags} />
      </MobileView>
    </Loading>
  );
});

export default ShoppingListIngredient;