import { del, post, put } from '../index';

export const addFamilyAdministrator = (familyId, userId) => {
  return post(`family/${familyId}/administrator/${userId}`);
};

export const deleteFamilyAdministrator = (familyId, userId) => {
  return del(`family/${familyId}/administrator/${userId}`);
};

export const addUserToFamily = (familyId, email) => {
  return put(`family/${familyId}/administrator/add/${email}`);
};

export const removeAwaitingUserFromFamily = (familyId, email) => {
  return del(`family/${familyId}/administrator/remove/${email}`);
};