import React, { useState, useEffect } from "react";
import { Row, Col, Card, Layout, Popconfirm } from 'antd';
import Loading from '../../../components/loading';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { basePath } from "../../../helpers/urlHelper";
import { getTimeIntervalOrDate } from "../../../helpers/dateHelper";
import { deleteImageJob, getAllImageJobs } from "../../../api/nomnom/Admin/ImageJob";

export const ImageJobs = React.memo(() => {
  const navigate = useNavigate();
  const { Content } = Layout;
  const [data, setData] = useState({
    items: [],
    currentPage: 1,
    pageSize: 20,
    hasNext: true,
    isLoading: true,
    isDeleteLoading: false
  });
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const loadData = (currentPage, pageSize, append = true) => {
    getAllImageJobs(currentPage, pageSize).then((resp) => {
      if (append) {
        setData({ ...resp, items: [...data.items, ...resp.items] });
      }
      else if (resp) {
        setData(resp);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData(1, data.pageSize, null, false);
    // eslint-disable-next-line
  }, []);

  const onFinish = (values) => {
    navigate(`${basePath()}/admin/image-job-types/add`);
  };

  const onDelete = (id) => {
    setDeleteLoading(true);
    deleteImageJob(id).then(() => {
      loadData(data.currentPage, data.pageSize, null, false);
      setDeleteLoading();
    });
  };

  return (
    <Loading isLoading={isLoading}>
      <Layout>
        <Content>
          <Row style={{ paddingLeft: "4em", paddingBottom: "10px" }}>
            <Col>
              <h2 style={{ margin: 0 }}>Image Jobs</h2>
            </Col>
          </Row>
          <InfiniteScroll
            style={{ overflow: 'none' }}
            dataLength={data.items ? data.items.length : 20} //This is important field to render the next data
            next={() => loadData(data.currentPage + 1, data.pageSize)}
            hasMore={data.hasNext}
            loader={<h4>Loading...</h4>}>
            <>
              {data.items && data.items.map(item => (
                <Row key={`row-${item.id}`}>
                  <Col offset={1} span={6} key={`col-${item.id}`}>
                    {item.id}
                  </Col>
                  <Col span={1}>
                    {item.imageJobTypeId}
                  </Col>
                  <Col span={1}>
                    {item.status}
                  </Col>
                  <Col span={14}>
                    {item.jobData}
                  </Col>
                  <Col>
                    <Popconfirm
                      title="Are you sure?"
                      onConfirm={() => onDelete(item.id)}
                      isLoading={isDeleteLoading}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined key={`delete-${item.id}`} />
                    </Popconfirm>
                  </Col>
                </Row>
              ))}
            </>
          </InfiniteScroll>
        </Content>
      </Layout>
    </Loading >
  );
});