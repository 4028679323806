import React, { useState } from 'react';
import { Card, Row, Col, Segmented } from 'antd';
import ReportCreatedByDateSlice from './ReportCreatedByDateSlice';

export default function Reports() {
  const [dateSlice, SetDateSlice] = useState("Daily");
  return (
    <>
      <h2>Reports</h2>
      <Segmented
        size="large"
        options={['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']}
        onChange={SetDateSlice}
      />
      <Row>
        <Col span={12}>
          <Card>
            <ReportCreatedByDateSlice entity={"UserProfile"} dateSlice={dateSlice} />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <ReportCreatedByDateSlice entity={"Ingredient"} dateSlice={dateSlice} />
          </Card>
        </Col>
      </Row >
      <Row>
        <Col span={12}>
          <Card>
            <ReportCreatedByDateSlice entity={"RecipeImport"} dateSlice={dateSlice} />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <ReportCreatedByDateSlice entity={"Recipe"} dateSlice={dateSlice} />
          </Card>
        </Col>
      </Row>
      <Row>

        <Col span={12}>
          <Card>
            <ReportCreatedByDateSlice entity={"Tag"} dateSlice={dateSlice} />
          </Card>
        </Col>
      </Row>
    </>
  );
};