import React from 'react';
import { Switch } from 'antd';
import useTheme from '../hooks/useTheme';

export const DarkModeSwitch = () => {
  const [darkMode, setDarkMode] = useTheme();

  return (
    <Switch
      checked={darkMode}
      onChange={setDarkMode}
      checkedChildren="Dark"
      unCheckedChildren="Light" />
  );
};