import React, { useState } from 'react';
import { NOMNOM_URL } from '../../config';
import { parseJwt } from '../../helpers/jwt';
import { getFamilyId, getJwtFromStorage } from '../../helpers/localstorage';
import { Card, Button, Row, Col, Space } from 'antd';
import { migrateDb } from '../../api/nomnom/Admin/Admin';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Form, Input, Statistic } from 'antd';
import { getRecipeImportMetadata } from '../../api/nomnom/Family/recipeImport';

export default function Configuration() {
  const [isMigrateDbLoading, setMigrateDbLoading] = useState(false);
  const [migrateDbState, setMigrateDbState] = useState();
  const [recipeImportMetadata, setRecipeImportMetadata] = useState();
  const [form] = Form.useForm();

  const migrateDbClick = () => {
    setMigrateDbState();
    setMigrateDbLoading(true);
    migrateDb().then(() => {
      setMigrateDbState("success");
      setMigrateDbLoading(false);
    }).catch(err => {
      setMigrateDbLoading(false);
      setMigrateDbState("error");
    });
  };

  const getMigrateDbIcon = () => {
    if (migrateDbState && migrateDbState === "success") return (<CheckCircleOutlined />);
    if (migrateDbState && migrateDbState === "error") return (<CloseCircleOutlined />);
  };

  const onGetRecipeImportMetadata = (values) => {
    getRecipeImportMetadata(getFamilyId(), values.urlToImport).then((response) => {
      setRecipeImportMetadata(response);
    });
  };

  return (
    <>
      <h2>Configuration</h2>
      <Card title="Configuration">
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: 'flex',
          }}>
          <Card type="inner" title="Database">
            <Row gutter={[16, 24]}>
              <Col className="gutter-row" span={24}>
              URL: {NOMNOM_URL}
              </Col>
              <Col className="gutter-row" span={24}>
                <Button icon={getMigrateDbIcon()} loading={isMigrateDbLoading} onClick={migrateDbClick}>Migrate DB</Button>
              </Col>
            </Row>
          </Card>

          <Card type="inner" title="Get Recipe Import Text">
            <Form
              name="basic"
              onFinish={onGetRecipeImportMetadata}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label="Url"
                name="urlToImport"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Button htmlType="submit">Get text</Button>
            </Form>

            {recipeImportMetadata && <>
              <Statistic title="Prompt Text Character Count" value={recipeImportMetadata?.promptText.length ?? 0} precision={0} />
              <Statistic title="Prompt Text Version" value={recipeImportMetadata?.promptTextVersion} />
              <Statistic title="Prompt Text Version" value={recipeImportMetadata?.hash} />
              <h1>Text</h1>
              <div dangerouslySetInnerHTML={{ __html: recipeImportMetadata.promptText }} />
            </>}

          </Card>
          <Card
            style={{ marginTop: 16 }}
            type="inner"
            title="Current User"
          >
            {JSON.stringify(parseJwt(getJwtFromStorage()))}
          </Card>
        </Space>
      </Card>
    </>
  );
};