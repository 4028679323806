import './signup.css';
import React, { useState } from 'react';
import { Form, Input, Button, Card, Result } from 'antd';
import { Link } from 'react-router-dom';
import { createUser } from '../../../api/nomnom/account';
import { basePath } from '../../../helpers/urlHelper';

export default function Signup() {
  const [loading, setLoading] = useState(false);
  const [createUserSuccessful, setCreateUserSuccessful] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    createUser(values.username, values.password).then((data) => {
      setLoading(false);
      setCreateUserSuccessful(true);
    }, () => { setLoading(false); });
  };

  return (
    <>
      {!createUserSuccessful && <>
        <Card>
          <div className="center">
            <h1>Create your username and password</h1>
          </div>
          <Form
            name="login"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 4 }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Email is required',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Password is required.',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 9,
                span: 5,
              }}
            >
              <Button type="primary" htmlType="submit" loading={loading}>
              Continue
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </>}

      {createUserSuccessful && <>
        <Result
          status="success"
          title="Successfully Created your account!"
          extra={[
            <Link to={`${basePath()}/login`}>
              <Button type="primary" key="console">
                Login
              </Button>
            </Link>
          ]}
        />
      </>}
    </>
  );
};