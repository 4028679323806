import './ListShoppingList.css';
import React, { useState, useEffect } from "react";
import { FloatButton, ConfigProvider, List } from 'antd';
import { ClearOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { getFamilyId, getShoppingListId } from "../../helpers/localstorage";
import { getShoppingListIngredients } from "../../api/nomnom/Family/shoppingListIngredient";
import { AddItemModal } from './AddItemModal';
import PendingList from './PendingList';
import { CompletedList } from './CompletedList';
import ListLayout from '../../components/layouts/ListLayout';
import { useSignalR } from '../../contexts/SignalRContext';
import { Puller } from '../../components/Puller';
import * as signalRCommands from "../../constants/signalRCommands";
export default function ListShoppingList() {
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const { postCommand, setEventRegistrations, DisplayWithStatus } = useSignalR();

  const loadData = () => {
    getShoppingListIngredients(getFamilyId(), getShoppingListId()).then((resp) => {
      setData(resp);
      setLoading(false);
    });
  };

  useEffect(() => {
    setEventRegistrations([
      { receiveFrom: signalRCommands.UPDATE_SHOPPING_LIST_INGREDIENT, onMessageReceived: loadData },
      { receiveFrom: signalRCommands.CLEAR_COMPLETED_SHOPPING_LIST_INGREDIENTS, onMessageReceived: loadData }
    ]);
    loadData();

    return () => {
      setEventRegistrations();
    };
    // eslint-disable-next-line
  }, []);

  const toggleCompleteIngredient = (ingredient) => {
    const updatedIngredient = { ...ingredient, isCompleted: !ingredient.isCompleted };
    postCommand(signalRCommands.UPDATE_SHOPPING_LIST_INGREDIENT, getFamilyId(), getShoppingListId(), updatedIngredient);
  };

  const onClearCompletedIngredients = () => {
    postCommand(signalRCommands.CLEAR_COMPLETED_SHOPPING_LIST_INGREDIENTS, getFamilyId(), getShoppingListId());
  };

  const customizeRenderEmpty = () => (
    <div
      style={{
        textAlign: 'center',
      }}
    >
      <UnorderedListOutlined
        style={{
          fontSize: 40,
        }}
      />
      <p>Shopping List is empty</p>
      <AddItemModal
        onClose={() => loadData()}
        shouldFloat={false}
      />
    </div>
  );

  const getLists = () => {
    if (Object.keys(data).length) {
      return (
        <>
          <PendingList
            items={data}
            toggleCompleteIngredient={toggleCompleteIngredient}
          />
          <CompletedList
            items={data}
            toggleCompleteIngredient={toggleCompleteIngredient}
          />
          <AddItemModal
            onClose={() => loadData()}
            shouldFloat={true}
          />
          <FloatButton
            icon={<ClearOutlined />}
            tooltip={<div>Clear completed items</div>}
            style={{ right: 96 }}
            onClick={onClearCompletedIngredients}
          />
        </>
      );
    }
    else {
      return (
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <List
            size="medium"
            bordered />
        </ConfigProvider>
      );
    }
  };

  return (
    <Puller>
      <DisplayWithStatus>
        <ListLayout
          isLoading={isLoading}
          children={getLists()}
        />
      </DisplayWithStatus>
    </Puller>
  );
};