import React, { useState } from "react";
import { Button, Popconfirm, Space } from 'antd';
import { useNavigate } from "react-router";
import { DeleteOutlined } from '@ant-design/icons';
import { deleteFamily } from "../../../api/nomnom/Family/family";
import { getFamilyId, localStorageKeys, setFamily, setShoppingList } from "../../../helpers/localstorage";
import { getDefaultShoppingList } from "../../../api/nomnom/Family/shoppingList";
import { getFamilies } from "../../../api/nomnom/UserProfile/userprofile";
import { getUserId } from "../../../helpers/jwt";

export default function DeleteWithPopover({ familyId }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const confirm = () => {
    setLoading(true);
    deleteFamily(familyId).then(() => {
      getFamilies(getUserId()).then((data) => {
        if (data.length) {
          setFamily(data[0]);
        }
        else {
          localStorage.removeItem(localStorageKeys.family);
        }

        getDefaultShoppingList(getFamilyId()).then((data) => {
          setShoppingList(data);
        });

        navigate(0);
      });
    });
  };

  return (
    <Space>
      <Popconfirm
        title="Delete Family"
        description="Are you sure to delete this family?"
        onConfirm={confirm}
        okText="Yes"
        cancelText="No"
      >
        <Button
          title="Delete Family"
          loading={loading}
          icon={<DeleteOutlined />}
        />
      </Popconfirm>
    </Space>
  );
};