export function convertUtcToLocal(utcDate) {
  return new Date(utcDate).toLocaleString('en-US', options).replace(/, /g, " ");;
}
const options = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
  timeZoneName: 'short'
};

export function getTimeIntervalOrDate(utcDate) {
  const now = new Date();
  const date = new Date(utcDate);
  const diffTime = Math.abs(now - date);

  // Convert time difference from milliseconds to days
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays > 1) {
    // If the difference is more than a day, return the local date
    return convertUtcToLocal(utcDate);
  }
  else {
    // Otherwise, return the interval in a human-readable format
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffMinutes = Math.floor(diffTime / (1000 * 60)) % 60;

    let interval = "";
    if (diffHours > 0) return diffHours + " hours ago";
    if (diffMinutes > 0) return interval += diffMinutes + " minutes ago";

    return "Just now";
  }
}