import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'antd';
import { getUnitsOfMeasure } from '../../api/nomnom/Family/recipe';
import Loading from '../../components/loading';
import { getFamilyId } from '../../helpers/localstorage';

export default function UnitsOfMeasure() {
  let [data, setData] = useState([]);
  let [isLoading, setLoading] = useState(true);
  useEffect(() => {
    loadData();
  }, [setData]);

  const loadData = () => {
    setLoading(true);
    getUnitsOfMeasure(getFamilyId()).then((resp) => {
      const results = resp.reduce((acc, value) => {
        // Group initialization
        if (!acc[value.baseUnit]) {
          acc[value.baseUnit] = [];
        }

        // Grouping
        acc[value.baseUnit].push(value);

        return acc;
      }, {});
      setData(results);
      setLoading(false);
    });
  };
  return (
    <>
      <h2>Units of Measure</h2>
      <Loading isLoading={isLoading}>

        {Object.keys(data).map(function (baseUnit) {
          return (
            <Card key={baseUnit} title={baseUnit}>
              <Row gutter={16}>
                {data[baseUnit].map(unitOfMeasure => {
                  return (
                    <Col key={unitOfMeasure.name} span={8}>
                      <Card type="inner" title={unitOfMeasure.name}>
                        <Row>Multiplier: {unitOfMeasure.multiplier}</Row>
                        <Row>Abbreviation: {unitOfMeasure.abbreviation || "None"}</Row>
                        <Row>Character: {unitOfMeasure.character || "None"}</Row>
                      </Card>
                    </Col>);
                })}
              </Row>
            </Card>
          );
        })
        }
      </Loading>
    </>
  );
};