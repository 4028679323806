import React from 'react';
import { Col, Row } from 'antd';
import { TitleContext } from '../../../../helpers/titleHelper';
import MenuDrawer from './MenuDrawer';
import { SafeArea } from 'antd-mobile';

const DisplayMobile = React.memo(() => {
  return (
    <>
      <div style={{ background: "#001529" }} >
        <SafeArea position="top" />
      </div>
      <Row>
        <Col span={23}>
          <TitleContext.Consumer>
            {value => <h1 style={{ position: "relative", bottom: 20 }}>{value.title}</h1>}
          </TitleContext.Consumer>

        </Col>
        <Col span={1}>
          <MenuDrawer />
        </Col>
      </Row>
    </>
  );
});

export default DisplayMobile;