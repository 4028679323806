import './List.css';
import React, { useState, useEffect, useRef } from "react";
import { Tag, Row, Col, Form, Input, Layout, Space, Button } from 'antd';
import { CapsuleTabs, Collapse, Ellipsis, InfiniteScroll, Dialog } from 'antd-mobile';
import { search as searchRecipes, deleteRecipe } from '../../../api/nomnom/Family/recipe';
import { getFamilyId } from "../../../helpers/localstorage";
import Loading from '../../../components/loading';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import parse from "html-react-parser";
import { basePath } from '../../../helpers/urlHelper';
import RecipeFront from './RecipeFront';
import { Puller } from "../../../components/Puller";
import { InfiniteScrollContent } from "../../../components/loading/InfiniteScrollContent";
import useTheme from '../../../hooks/useTheme';

export const DisplayMobile = React.memo(() => {
  const [darkMode] = useTheme();
  const { Content } = Layout;
  const navigate = useNavigate();
  const [data, setData] = useState({
    items: [],
    currentPage: 1,
    pageSize: 20,
    hasNext: true
  });
  const inputRef = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const [search, setSearch] = useState();

  const loadData = (currentPage, pageSize, search, append = true) => {
    searchRecipes(getFamilyId(), currentPage, pageSize, search, null).then((resp) => {
      if (append) {
        setData({ ...resp, items: [...data.items, ...resp.items] });
      }
      else if (resp) {
        setData(resp);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData(1, data.pageSize, null, false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isLoading)
      inputRef.current.focus({
        cursor: 'start',
      });
  }, [isLoading]);

  const onDelete = (recipeId) => {
    deleteRecipe(getFamilyId(), recipeId).then(() => {
      loadData(data.currentPage, data.pageSize, null, false);
    });
  };

  const onSearchChange = (e) => {
    setSearch(e.target.value);
    loadData(1, 20, e.target.value ?? null, false);
  };

  const onAdd = () => {
    navigate(`${basePath()}/recipes/add?${search ? `name=${search}` : ''}`);
  };

  return (
    <Loading isLoading={isLoading}>
      <Content>
        <Puller>
          <Form
            name="search"
            onFinish={onAdd}
            autoComplete="off"
          >
            <Row style={{ padding: "10px" }}>
              <Col span={21} >
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={onSearchChange}
                  ref={inputRef}
                />
              </Col>
              <Col span={3}>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }} icon={<PlusOutlined />} />
              </Col>
            </Row>
          </Form>
          <Collapse accordion="true">
            {data.items && data.items.map(item => (
              <Collapse.Panel key={item.key} title={
                <Row>
                  <Col span={19}>
                    <Space>
                      <Ellipsis direction="end" content={item.name} />
                      {item.tags.map(tag => <Tag>{tag.name}</Tag>)}
                    </Space>
                  </Col>
                  <Col span={5}>
                    <Link to={`../${item.id}`}>
                      <Button color="primary" size="small" fill="outline">
                        <EditOutlined />
                      </Button>
                    </Link>
                    <Button
                      size="small"
                      onClick={() =>
                        Dialog.confirm({
                          confirmText: "Ok",
                          cancelText: "Cancel",
                          content: 'Delete',

                          onConfirm: () => onDelete(item.id),
                        })
                      }
                    >
                      <DeleteOutlined key={`delete-${item.id}`} />
                    </Button>
                  </Col>
                </Row>}>
                {item.directions && <CapsuleTabs defaultActiveKey="ingredients">
                  <CapsuleTabs.Tab title="Ingredients" key="ingredients">
                    <RecipeFront recipe={item} />
                  </CapsuleTabs.Tab>
                  <CapsuleTabs.Tab title="Directions" key="directions">
                    <span className={`${darkMode ? "dark" : "light"}-recipe`} style={{ fontSize: 18 }}>{parse(item?.directions ?? "")}</span>
                  </CapsuleTabs.Tab>
                </CapsuleTabs>}
                {!item.directions && <>
                  <RecipeFront recipe={item} />
                </>}
              </Collapse.Panel>
            ))}
          </Collapse>
          <InfiniteScroll
            loadMore={() => loadData(data.currentPage + 1, data.pageSize)}
            hasMore={data.hasNext}
          >
            <InfiniteScrollContent hasMore={data.hasNext} />
          </InfiniteScroll>
        </Puller>
      </Content >
    </Loading>
  );
});