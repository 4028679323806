import React from "react";
import { Row, Col, Button, Tooltip, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getFamilyId, getShoppingListId } from '../../../../helpers/localstorage';
import { useSignalR } from '../../../../contexts/SignalRContext';
import useTheme from "../../../../hooks/useTheme";
import * as signalRCommands from '../../../../constants/signalRCommands';

export const DisplayMobile = React.memo(({ recipe }) => {
  const [api, contextHolder] = notification.useNotification();
  const { postCommand } = useSignalR();
  const [darkMode] = useTheme();
  const openNotification = (ingredientName) => {
    api.info({
      message: `${ingredientName} successfully add to list.`,
      placement: 'bottomRight',
    });
  };

  const onAddToShoppingList = (ingredientId, ingredientName) => {
    const ingredient = { id: 0, ingredientId: ingredientId, quantity: 1 };
    postCommand(signalRCommands.UPDATE_SHOPPING_LIST_INGREDIENT, getFamilyId(), getShoppingListId(), ingredient);
    openNotification(ingredientName);
  };
  const formatAmount = (amount, unitOfMeasureAbbreviation) => {
    let unitOfMeasureAbbreviationResult = unitOfMeasureAbbreviation ?? '';
    if ((!amount || amount === '0') && !unitOfMeasureAbbreviation) {
      return '';
    }
    else if (unitOfMeasureAbbreviation) {
      unitOfMeasureAbbreviationResult = ` ${unitOfMeasureAbbreviation}`;
    }
    return `(${amount}${unitOfMeasureAbbreviationResult})`;
  };

  const getMiddle = () => {
    if (recipe.ingredients.length < 5)
      return 5;
    return Math.round((recipe?.ingredients?.length ?? 0) / 2) + ((Math.round((recipe?.ingredients?.length ?? 0) / 2) % 2));
  };

  const renderIngredient = (ingredient) => (
    <Row style={{ marginTop: "1em" }}>
      <Col span xl={{ span: 1 }} style={{ marginRight: "1em" }}>
        <Tooltip title="add to list">
          <Button shape="circle" size="small" icon={<PlusOutlined />} onClick={() => onAddToShoppingList(ingredient.ingredientId, ingredient.name)} />
        </Tooltip>
      </Col>
      <Col xl={{ span: 22 }} key={ingredient.name}>
        {ingredient.name} {formatAmount(ingredient.amountString, ingredient.unitOfMeasureAbbreviation)}
      </Col>
    </Row>);

  return (
    <Row className={`${darkMode ? "dark" : "light"}-recipe`}>
      <Col md={{ span: 12 }} sm={{ span: 24 }}>
        {contextHolder}
        {recipe.ingredients?.slice(0, getMiddle()).map((ingredient) => renderIngredient(ingredient))}
      </Col>
      <Col md={{ span: 12 }} sm={{ span: 24 }}>
        {recipe.ingredients?.slice(getMiddle()).map((ingredient) => renderIngredient(ingredient))}
      </Col>
    </Row>
  );
});