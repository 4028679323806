import React, { useRef, useEffect } from "react";
import { AutoComplete } from 'antd';

export const AutoFocusAutoComplete = React.memo((props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus({
      cursor: 'end',
    });
  }, []);

  return (
    <AutoComplete ref={inputRef}
      {...props} />
  );
});