import { del, get, post, put } from '../index';

export const createImageJobType = (imageJobType) => {
  return post('admin/image-job-type', imageJobType);
};

export const updateImageJobType = (imageJobType) => {
  return put('admin/image-job-type', imageJobType);
};

export const getImageJobType = (id) => {
  return get(`admin/image-job-type/${id}`);
};

export const deleteImageJobType = (id) => {
  return del(`admin/image-job-type/${id}`);
};

export const getAllImageJobType = (page, size) => {
  return get(`admin/image-job-type?page=${page}&size=${size}`);
};
