import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Empty } from 'antd';

import './index.css';

import App from './components/app';

import { homePath } from './helpers/urlHelper';

import ProtectedRoute from './routes/ProtectedRoute';

import { ADMIN, USER } from './constants/roles';

import Login from './pages/Authentication';
import Forgot from './pages/Authentication/forgot';
import Signup from './pages/Authentication/signup';

import SharedFamily from './pages/UserProfile/Family';

import Family from './pages/Family';
import FamilyGeneral from './pages/Family/General';

import FamilyListTagCategories from './pages/Family/TagCategories/List';
import FamilyTagCategory from './pages/Family/TagCategories/TagCategory';
import FamilyAddTagCategory from './pages/Family/TagCategories/Add';

import FamilyListRecipeImports from './pages/Family/RecipeImport/List';
import FamilyRecipeImport from './pages/Family/RecipeImport/RecipeImport';
import FamilyAddRecipeImport from './pages/Family/RecipeImport/Add';

import UserProfile from './pages/UserProfile';
import Families from './pages/UserProfile/Family';
import UserProfileGeneral from './pages/UserProfile/General';

import Admin from './pages/Admin';
import AdminConfiguration from './pages/Admin/Configuration';
import AdminFamilies from './pages/Admin/Families';
import AdminUnitsOfMeasure from './pages/Admin/UnitsOfMeasure';

import ShoppingList from './pages/ShoppingList';
import ListShoppingList from './pages/ShoppingList/ListShoppingList';
import ShoppingListIngredient from './pages/ShoppingList/ShoppingListIngredient';

import Ingredients from './pages/Ingredients';
import AddIngredient from './pages/Ingredients/Add';
import EditIngredient from './pages/Ingredients/Edit';
import IngredientsList from './pages/Ingredients/List';

import Recipes from './pages/Recipes';
import AddRecipe from './pages/Recipes/Add';
import EditRecipe from './pages/Recipes/Edit';
import RecipeList from './pages/Recipes/List';

import { SignalRProvider } from './contexts/SignalRContext';
import Reports from './pages/Admin/Reports';
import { ImageJobTypes } from './pages/Admin/ImageJobTypes';
import { AddImageJobType } from './pages/Admin/ImageJobTypes/AddImageJobType';
import { EditImageJobType } from './pages/Admin/ImageJobTypes/EditImageJobType';
import { ImageJobs } from './pages/Admin/ImageJobs';

const SuggestNavigationIndex = (<Empty
  image={<ArrowLeftOutlined />}
  imageStyle={{
    fontSize: 50,
  }}
  description={
    <span>
      Use Left Navigation
    </span>
  } />);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <SignalRProvider>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path={homePath()} element={<App />}>
            <Route path="login">
              <Route index element={<Login />} />
              <Route path="forgot" element={<Forgot />} />
              <Route path="signup" element={<Signup />} />
            </Route>
            <Route path="userprofile" element={<ProtectedRoute allowedRoles={[USER]} component={<UserProfile />} />}>
              <Route index element={<UserProfileGeneral />} />
              <Route path="general" element={<UserProfileGeneral />} />
              <Route path="families" element={<Families />} />
            </Route>
            <Route path="recipes" element={<ProtectedRoute allowedRoles={[USER]} component={<Recipes />} />}>
              <Route path="list" element={<ProtectedRoute allowedRoles={[USER]} component={<RecipeList />} />} />
              <Route path="add" element={<ProtectedRoute allowedRoles={[USER]} component={<AddRecipe />} />} />
              <Route path=":recipeId" element={<ProtectedRoute allowedRoles={[USER]} component={<EditRecipe />} />} />
            </Route>
            <Route path="ingredients" element={<ProtectedRoute allowedRoles={[USER]} component={<Ingredients />} />}>
              <Route path="list" element={<ProtectedRoute allowedRoles={[USER]} component={<IngredientsList />} />} />
              <Route path="add" element={<ProtectedRoute allowedRoles={[USER]} component={<AddIngredient />} />} />
              <Route path=":ingredientId" element={<ProtectedRoute allowedRoles={[USER]} component={<EditIngredient />} />} />
            </Route>
            <Route path="list" element={<ProtectedRoute allowedRoles={[USER]} component={<ShoppingList />} />} >
              <Route index element={<ListShoppingList />} />
              <Route path=":shoppingListIngredientId" element={<ProtectedRoute allowedRoles={[USER]} component={<ShoppingListIngredient />} />} />
            </Route>
            <Route path="admin" element={<ProtectedRoute allowedRoles={[ADMIN]} component={<Admin />} />}>
              <Route index element={SuggestNavigationIndex} />
              <Route path="families" element={<AdminFamilies />}>
                <Route path=":familyId" element={<SharedFamily />} />
              </Route>
              <Route path="units-of-measure">
                <Route index element={<AdminUnitsOfMeasure />} />
              </Route>
              <Route path="configuration" element={<AdminConfiguration />} />
              <Route path="reports" element={<Reports />} />
              <Route path="image-job-types">
                <Route index element={<ImageJobTypes />} />
                <Route path="add" element={<AddImageJobType />} />
                <Route path=":imageJobTypeId" element={<EditImageJobType />} />
              </Route>
              <Route path="image-jobs">
                <Route index element={<ImageJobs />} />
              </Route>
            </Route>
            <Route path="family" element={<ProtectedRoute allowedRoles={[USER]} component={<Family />} />}>
              <Route path="general" element={<FamilyGeneral />} />
              <Route path="tag-categories" >
                <Route path="list" element={<FamilyListTagCategories />} />
                <Route path=":tagCategoryId" element={<FamilyTagCategory />} />
                <Route path="add" element={<FamilyAddTagCategory />} />
              </Route>
              <Route path="recipe-imports" >
                <Route path="list" element={<FamilyListRecipeImports />} />
                <Route path=":recipeImportId" element={<FamilyRecipeImport />} />
                <Route path="add" element={<FamilyAddRecipeImport />} />
              </Route>
            </Route>
          </Route>
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </SignalRProvider>
);

