import { Row, Col, Button, Form, List, notification, Tooltip, Space } from 'antd';
import React, { useState, useEffect } from "react";
import { search as searchIngredients } from '../../../api/nomnom/Family/ingredient';
import { getFamilyId, getShoppingListId } from "../../../helpers/localstorage";
import Loading from '../../../components/loading';
import { PlusOutlined, FormOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import EditableTagGroup from '../../../components/tags/EditableTagGroup';
import VirtualList from 'rc-virtual-list';
import { basePath } from '../../../helpers/urlHelper';
import { Link } from 'react-router-dom';
import { useSignalR } from '../../../contexts/SignalRContext';
import { AutoFocusInput } from '../../../components/Form/AutoFocusInput';
import * as signalRCommands from '../../../constants/signalRCommands';

export function DisplayBrowser() {
  const { postCommand } = useSignalR();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({
    items: [],
    currentPage: 1,
    pageSize: 20,
    hasNext: true,
    isLoading: true
  });

  const [isLoading, setLoading] = useState(true);
  const [search, setSearch] = useState();

  const loadData = (currentPage, pageSize, search, append = true) => {
    searchIngredients(getFamilyId(), currentPage, pageSize, search).then((resp) => {
      if (append) {
        setData({ ...resp, items: [...data.items, ...resp.items] });
      }
      else {
        setData(resp);
      }
      setLoading(false);
    });
  };


  const onScroll = (e) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === containerHeight) {
      loadData(data.currentPage + 1, data.pageSize);
    }
  };

  useEffect(() => {
    loadData(1, data.pageSize, null);
    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    setSearch(e.target.value);
    loadData(1, 20, e.target.value ?? null, false);
  };

  const onFinish = (values) => {
    navigate(`${basePath()}/ingredients/add?${search ? `name=${search}` : ''}`);
  };

  const openNotification = (ingredientName) => {
    api.info({
      message: `${ingredientName} successfully add to list.`,
      placement: 'bottomRight',
    });
  };

  const onAddToShoppingList = (ingredientId, ingredientName) => {
    const ingredient = { id: 0, ingredientId: ingredientId, quantity: 1 };
    postCommand(signalRCommands.UPDATE_SHOPPING_LIST_INGREDIENT, getFamilyId(), getShoppingListId(), ingredient);
    openNotification(ingredientName);
  };

  const containerHeight = 650;

  return (
    <>
      {contextHolder}
      <Form
        name="search"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row style={{ paddingBottom: "10px" }}>
          <Col xs={{ span: 21 }}>
            <AutoFocusInput
              style={{
                width: "100%",
              }}
              onChange={onChange} />

          </Col>
          <Col xs={{ span: 2 }}>
            <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>Add</Button>
          </Col>
        </Row>
      </Form>
      <Loading isLoading={isLoading}>
        <List
          bordered
          size="small">
          <VirtualList
            data={data.items}
            height={containerHeight}
            onScroll={onScroll}
          >
            {(item) => (
              <List.Item key={`list-item-${item.key}`}>
                <List.Item.Meta
                  title={
                    <Row>
                      <Col md={{ span: 2 }} xs={{ span: 3 }}>
                        <Tooltip title="add to list">
                          <Button shape="circle" style={{ marginRight: "1em" }} size="small" icon={<PlusOutlined />} onClick={() => onAddToShoppingList(item.id, item.name)} />
                        </Tooltip>
                      </Col>
                      <Col md={{ span: 19 }} xs={{ span: 16 }}>
                        <Space size="small">
                          {item.name}
                          <EditableTagGroup ingredientId={item.id} tags={item.tags} />
                        </Space>
                      </Col>
                      <Col md={{ span: 3 }} xs={{ span: 5 }}>
                        <Space size="small">
                          <Tooltip title="Edit">
                            <Link to={`../${item.id}`}>
                              <Button icon={<FormOutlined />} />
                            </Link>
                          </Tooltip>
                        </Space>
                      </Col>
                    </Row>}
                />
              </List.Item>
            )}
          </VirtualList>
        </List>
      </Loading>
    </>
  );
};