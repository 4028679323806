import { get, del } from '../index';

export const getFamilies = (userId) => {
  return get(`userprofile/${userId}/families`);
};

export const leaveFamily = (userId, familyId) => {
  return del(`userprofile/${userId}/families/${familyId}`);
};

export const getUserProfile = (userId) => {
  return get(`userprofile/${userId}`);
};