import { del, get, put } from '../index';

export const refireImageJob = (id) => {
  return put(`admin/image-job/${id}/refire`);
};

export const deleteImageJob = (id) => {
  return del(`admin/image-job/${id}`);
};

export const getAllImageJobs = (page, size) => {
  return get(`admin/image-job?page=${page}&size=${size}`);
};
