import React from 'react';
import { Link } from 'react-router-dom';
import SubLayout from '../../components/layouts/SubLayout';
import { Menu } from 'antd';
import { basePath } from "../../helpers/urlHelper";

export default function Index() {
  const subMenu = (<>
    <Menu.Item key="general">
      <Link to={basePath("family/general")} >
        General
      </Link>
    </Menu.Item>
    <Menu.Item key="recipe-imports">
      <Link to={basePath("family/recipe-imports/list")}>
        Recipe Imports
      </Link>
    </Menu.Item>
    <Menu.Item key="tag-categories">
      <Link to={basePath("family/tag-categories/list")}>
        Tag Categories
      </Link>
    </Menu.Item>
  </>);
  return (
    <>
      <SubLayout
        subMenu={subMenu}
        title="Family"
        defaultSelectedKeys={['general']}
      />
    </>
  );
};