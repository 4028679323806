import React, { useRef, useEffect } from "react";
import { Input } from 'antd';

export function AutoFocusInput(props) {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus({
      cursor: 'end',
    });
  }, []);

  return (
    <Input ref={inputRef}
      {...props} />
  );
};