import React from 'react';
import Loading from '../loading';
import { Row, Col } from 'antd';

export default function ListLayout({ children, isLoading }) {
  return (
    <Loading isLoading={isLoading}>
      <Row>
        <Col xl={{ offset: 6, span: 12 }} md={{ offset: 2, span: 20 }} xs={{ span: 24 }}>
          {children}
        </Col>
      </Row>
    </Loading>
  );
}