import React, { useState, useRef, useEffect } from 'react';
import { Button, Form, Input, Alert } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { createRecipe } from '../../api/nomnom/Family/recipe';
import { getFamilyId } from '../../helpers/localstorage';
import { basePath } from '../../helpers/urlHelper';

export default function Add() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus({
      cursor: 'end',
    });
  }, []);

  const onFinish = (values) => {
    setError();
    setLoading(true);
    createRecipe(getFamilyId(), values).then((response) => {
      setLoading(false);
      if (!response.status) {
        navigate(`${basePath()}/recipes/list`);
      }
      else {
        setError(response.data);
      }
    });
  };

  return (
    <>
      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="id"
          initialValue={0}
          hidden
        />
        <Form.Item
          name="directions"
          initialValue=""
          hidden
        />
        <Form.Item
          label="Name"
          name="name"
          initialValue={location.search ? decodeURIComponent(location.search.replace('?name=', '')) : null}
          rules={[{ required: true, message: 'Please add a name!' }]}

        >
          <Input ref={inputRef} />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
        {error && <Alert message={error} type="error" />}
      </Form>
    </>
  );
};