import { get, post, del } from '../index';

export const search = (familyId, page, size, status) => {
  const searchQuery = status ? `status=${status}&` : '';

  return get(`family/${familyId}/recipe-import/search?${searchQuery}page=${page}&size=${size}`);
};

export const createRecipeImport = (familyId, request) => {
  return post(`family/${familyId}/recipe-import`, request);
};

export const finalizeRecipeImport = (familyId, recipeImportId, request) => {
  return post(`family/${familyId}/recipe-import/${recipeImportId}/finalize`, request);
};

export const deleteRecipeImport = (familyId, id) => {
  return del(`family/${familyId}/recipe-import/${id}`);
};

export const getRecipeImport = (familyId, id) => {
  return get(`family/${familyId}/recipe-import/${id}`);
};

export const getRecipe = (familyId, id) => {
  return get(`family/${familyId}/recipe-import/${id}/recipe`);
};

export const getRecipeImportMetadata = (familyId, url) => {
  return get(`family/${familyId}/recipe-import/metadata?urlToImport=${url}`);
};