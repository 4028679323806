import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { removeUserFromFamily, updateFamily } from "../../../api/nomnom/Family/family";
import { Card, Row, Space, Col, Button, Tag, Switch } from 'antd';
import Loading from '../../../components/loading';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { addFamilyAdministrator, deleteFamilyAdministrator, removeAwaitingUserFromFamily } from "../../../api/nomnom/Family/administrator";
import { getUserId, isInRole, parseJwt } from '../../../helpers/jwt';
import { ADMIN } from "../../../constants/roles";
import EditFamilyNameModal from "./EditFamilyNameModal";
import { getFamily as getLocalFamily, getJwtFromStorage } from "../../../helpers/localstorage";
import AddUserToFamilyModal from "./AddUserToFamilyModal";
import { useFetchFamily } from "../../../api/nomnom/Family/useFetchFamily";
import DeleteWithPopover from "./DeleteWithPopover";
import { getFamilies } from "../../../api/nomnom/UserProfile/userprofile";

export default function Family() {
  const params = useParams();
  const navigate = useNavigate();

  const familyId = params?.familyId ?? getLocalFamily().id;
  const familyResponse = useFetchFamily(familyId);
  const isAdministrator = (isInRole(ADMIN) || familyResponse.data?.administrators.filter(a => a.id === getUserId()).length > 0);
  const hasMoreThanOneFamily = getFamilies(getUserId()).length > 0;

  const onAddAdministrator = (userId) => {
    addFamilyAdministrator(familyId, userId).then(() => navigate(0));
  };

  const onDeleteAdministrator = (userId) => {
    deleteFamilyAdministrator(familyId, userId).then(() => navigate(0));
  };

  const onRemoveUserFromFamily = (userId) => {
    removeUserFromFamily(familyId, userId).then(() => navigate(0));
  };

  const onRemoveAwaitingNewUser = (email) => {
    removeAwaitingUserFromFamily(familyId, email)
      .then(() => navigate(0));
  };

  const onChange = (family) => {
    updateFamily({ ...family, isPrivate: !family.isPrivate })
      .then(() => {
        navigate(0);
      });
  };

  return (
    <Loading isLoading={familyResponse.isLoading}>
      <Card title={<Space>
        {familyResponse?.data?.name}
        {isAdministrator && <EditFamilyNameModal key="edit-family-modal" family={familyResponse.data} />}
        {isAdministrator && hasMoreThanOneFamily && <DeleteWithPopover key="delete-family-modal" familyId={familyResponse.data.id} />}
      </Space>}>
        <Row>
          <Col span={12}>
            <Space><h1>Users</h1>
              <Switch
                key="isPrivate"
                defaultChecked={!familyResponse.data?.isPrivate}
                checkedChildren="Public"
                unCheckedChildren="Private"
                onChange={() => onChange(familyResponse.data)} />
              {isAdministrator && <AddUserToFamilyModal family={familyResponse.data} />}
            </Space>
            {familyResponse.data?.users.map(user => (
              <Space key={user.id} direction="vertical" size="large" style={{ display: 'flex' }}>
                <Row>
                  <Space>
                    {user.username}{familyResponse.data.administrators.filter(a => a.username === user.username).length === 0 && isAdministrator &&
                      <Button title="Make user a family administrator" onClick={() => onAddAdministrator(user.id)} icon={<PlusOutlined />} />}
                    {isAdministrator &&
                      parseJwt(getJwtFromStorage()).preferred_username !== user.username && <Button title="Remove user from family" onClick={() => onRemoveUserFromFamily(user.id)} icon={<DeleteOutlined />} />}
                  </Space>
                </Row>
              </Space>
            ))}
            {familyResponse.data?.awaitingNewUsers.map(email => (
              <Space key={email} direction="vertical" size="large" style={{ display: 'flex' }}>
                <Row>
                  <Space>
                    {email}<Tag>Waiting for user to sign in</Tag>{isAdministrator && <Button title="Remove user from family" onClick={() => onRemoveAwaitingNewUser(email)} icon={<DeleteOutlined />} />}
                  </Space>
                </Row>
              </Space>
            ))}
          </Col>
          <Col span={12}>
            <h1>Family Administrators</h1>
            {familyResponse.data?.administrators.map(user => (
              <Space key={user.id} direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Row>
                  <Space>
                    {user.username}{familyResponse.data.administrators.length > 1 && isAdministrator && <Button title="Remove user from administrators" onClick={() => onDeleteAdministrator(user.id)} icon={<DeleteOutlined />} />}
                  </Space>
                </Row>
              </Space>
            ))}
          </Col>
        </Row>
      </Card>
    </Loading>
  );
};