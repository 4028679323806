import { useNavigate } from "react-router";
import { clearLocalStorage } from "../helpers/localstorage";

export default function useLogout() {
  const navigate = useNavigate();

  const handleLogout = () => {
    clearLocalStorage();
    navigate("/login");
  };

  return [handleLogout];
};