import React from "react";
import { useFetchUserProfile } from "../../api/nomnom/UserProfile/useFetchUserProfile";
import { parseJwt } from "../../helpers/jwt";
import { getJwtFromStorage } from "../../helpers/localstorage";
import Loading from "../../components/loading";

export default function General() {
  const response = useFetchUserProfile();
  
  return (
    <Loading isLoading={response.isLoading}>
      {response.data&&<>
        <h2>General</h2>
        <div>
          Username: {response.data.username}
        </div>
        <div>
          Member since: {response.data.createdDate}
        </div>
        <div>
          {JSON.stringify(parseJwt(getJwtFromStorage()))}
        </div>
      </>}
    </Loading>
  );
};