import './SignalRContext.css';
import React, { useState, useEffect, useContext } from "react";
import { NOMNOM_HUB_SHOPPING_LIST_INGREDIENT } from "../config";
import * as signalR from "@microsoft/signalr";
import { getFamilyId, getJwtFromStorage, getShoppingListId } from "../helpers/localstorage";
import { Badge, Space, Spin } from 'antd';
import { Result } from 'antd-mobile';
import { isMobile } from 'react-device-detect';

const SignalRContext = React.createContext();

const SignalRProvider = ({ children }) => {
  const [connection, setConnection] = useState();
  const [forceEffect, setForceEffect] = useState();
  const [eventRegistrations, setEventRegistrations] = useState(null);
  const [status, setStatus] = useState(signalR.HubConnectionState.Reconnecting);
  let date = new Date().getTime();

  const leaveGroup = (connection) => {
    if (connection)
      connection.send("LeaveGroupAsync", getFamilyId(), getShoppingListId());
  };

  //register event handlers
  useEffect(() => {
    if (connection?._connectionState && eventRegistrations) {
      // eslint-disable-next-line
      eventRegistrations.map(registration => {
        connection.on(registration.receiveFrom, (response, userId) => {
          registration.onMessageReceived(response, userId);
        });
      });
    }
    // eslint-disable-next-line
  }, [eventRegistrations]);

  const startConnection = () => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(NOMNOM_HUB_SHOPPING_LIST_INGREDIENT, {
        accessTokenFactory: () => getJwtFromStorage(),
        transport: signalR.HttpTransportType.LongPolling
      })
      .withAutomaticReconnect([0, 1000, 2000, 2000, 2000, 2000, 2000, 10000, 30000, 30000, 30000])
      //.configureLogging(signalR.LogLevel.Debug)
      .build();

    // eslint-disable-next-line
      newConnection.start().catch(err => {
      // eslint-disable-next-line
        console.error("unable to connect", err);
      startConnection();
    }).then(() => {
      if (newConnection.state === signalR.HubConnectionState.Connected) {
        setStatus(signalR.HubConnectionState.Connected);
        newConnection.invoke('JoinGroupAsync', getFamilyId(), getShoppingListId());
      }
      newConnection.onreconnecting(error => {
        setStatus(signalR.HubConnectionState.Reconnecting);
      });
      newConnection.onreconnected(connectionId => {
        setStatus(signalR.HubConnectionState.Connected);
      });

      newConnection.onclose(error => {
        setStatus(signalR.HubConnectionState.Disconnected);
        startConnection();
      });
    });
    return newConnection;
  };
  const onVisibilityChange = (value) => {
    if (isMobile && document.visibilityState === "visible") {
      const newDate = new Date().getTime();

      const timeDifference = Math.abs(newDate - date);
      const timeDifferenceInSeconds = timeDifference / 1000;

      // console.log("checking should refresh", timeDifferenceInSeconds);
      if (timeDifferenceInSeconds > 30) {
        window.location.reload();
      }

    }
    else {
      date = new Date().getTime();
    }
  };

  //on startup
  useEffect(() => {
    if (getJwtFromStorage()) {
      const newConnection = startConnection();
      setConnection(newConnection);
      if (!document.onvisibilitychange) {
        document.addEventListener("visibilitychange", onVisibilityChange);
      }

      return () => {
        document.removeEventListener("visivilityChange", onVisibilityChange);
        if (status === signalR.HubConnectionState.Connected)
          newConnection.stop();
      };
    }
    else if (connection) {
      connection.stop();
    }
    // eslint-disable-next-line
  }, [forceEffect]);


  const postCommand = (command, familyId, shoppingListId, model) => {
    connection.send(command, familyId, shoppingListId, model);
  };

  const GetConnectingResult = () => {
    return (
      <>
        <Result
          icon={<Spin size="large" />}
          title="Connecting"
        />
      </>
    );
  };

  const GetDisconnectedResult = () => {
    return (<Result
      status="error"
      title="Unable to connect"
    />);
  };

  const DisplayWithStatus = ({ children }) => {
    if (status !== signalR.HubConnectionState.Connected) {
      return (
        <div className="loading-container">
          <Space size="middle">
            {status === signalR.HubConnectionState.Disconnected &&
              GetDisconnectedResult()
            }
            {status === signalR.HubConnectionState.Reconnecting &&
              GetConnectingResult()
            }
          </Space>
        </div>
      );
    }
    return (children);
  };

  const DisplayStatusBadge = () => {
    switch (status) {
    case signalR.HubConnectionState.Connected:
      return (<Badge status="success" />);
    case signalR.HubConnectionState.Disconnected:
      return (<Badge status="error" />);
    default: //signalR.HubConnectionState.Reconnecting:
      return (<Badge status="warning" />);
    }
  };

  return (
    <SignalRContext.Provider value={{
      postCommand,
      setEventRegistrations,
      DisplayWithStatus,
      leaveGroup,
      connection,
      DisplayStatusBadge,
      setForceEffect
    }}>
      {children}
    </SignalRContext.Provider>
  );
};

const useSignalR = () => useContext(SignalRContext);

export { SignalRProvider, useSignalR };