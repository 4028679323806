import { get, post, del, put } from '../index';

export const getUnitsOfMeasure = (familyId) => {
  return get(`family/${familyId}/recipe/units-of-measure`);
};

export const search = (familyId, page, size, search, tag) => {
  const searchQuery = search ? `search=${search}&` : '';
  const tagQuery = tag ? `tag=${tag}&` : '';

  return get(`family/${familyId}/recipe/search?${searchQuery}${tagQuery}page=${page}&size=${size}`);
};

export const createRecipe = (familyId, request) => {
  return post(`family/${familyId}/recipe`, request);
};

export const deleteRecipe = (familyId, id) => {
  return del(`family/${familyId}/recipe/${id}`);
};

export const updateRecipe = (familyId, request) => {
  return put(`family/${familyId}/recipe`, request);
};

export const getRecipe = (familyId, recipeId) => {
  return get(`family/${familyId}/recipe/${recipeId}`);
};

export const addTag = (familyId, recipeId, request) => {
  return post(`family/${familyId}/recipe/${recipeId}/tag`, request);
};

export const deleteTag = (familyId, recipeId, tagId) => {
  return del(`family/${familyId}/recipe/${recipeId}/tag/${tagId}`);
};

export const getTags = (familyId) => {
  return get(`family/${familyId}/recipe/tag`);
};

export const regenerateImages = (familyId, recipeId) => {
  return post(`family/${familyId}/recipe/${recipeId}/image/regenerate`);
};

export const getRecipeImages = (familyId, recipeId, page, size) => {
  return get(`family/${familyId}/recipe/${recipeId}/image?page=${page}&size=${size}`);
};

export const deleteRecipeImage = (familyId, recipeId, imageId) => {
  return del(`family/${familyId}/recipe/${recipeId}/image/${imageId}`);
};

export const setActiveImage = (familyId, recipeId, imageId) => {
  return put(`family/${familyId}/recipe/${recipeId}/image/${imageId}`);
};