import React, { useState } from "react";
import { Input, Modal, Button, Form } from 'antd';
import { createFamily } from "../../api/nomnom/Family/family";
import { useNavigate } from "react-router";
import { setFamily } from "../../helpers/localstorage";

export default function CreateFamilyModal({ className }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };
  const onFinish = (values) => {
    setLoading(true);
    createFamily(values).then((family) => {
      setFamily(family);
      form.resetFields();
      navigate(0);
      setOpen(false);
    });
  };

  return (
    <>
      <Button type="link" className={className} size="small" onClick={showModal}>
        New Family
      </Button>
      <Modal
        open={open}
        title="Add Family"
        destroyOnClose={true}
        onOk={() => {
          form.validateFields().then((values) => {
            onFinish(values);
          });
        }}
        confirmLoading={loading}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          name="createFamily"
          onFinish={onFinish}
        >
          <Form.Item
            label="Family Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Family name is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};