import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { DisplayMobile } from './DisplayMobile';
import { DisplayBrowser } from './DisplayBrowser';

const PendingList = React.memo(({ items, toggleCompleteIngredient }) => {
  return (
    <>
      <BrowserView>
        <DisplayBrowser
          toggleCompleteIngredient={toggleCompleteIngredient}
          items={items} />
      </BrowserView>
      <MobileView>
        <DisplayMobile
          toggleCompleteIngredient={toggleCompleteIngredient}
          items={items} />
      </MobileView>
    </>
  );
});

export default PendingList;