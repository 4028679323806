import React, { useState } from 'react';
import { Button, Form, Input, Alert } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { createIngredient } from '../../api/nomnom/Family/ingredient';
import { getFamilyId } from '../../helpers/localstorage';
import { basePath } from '../../helpers/urlHelper';
import { AutoFocusInput } from '../../components/Form/AutoFocusInput';

export default function Add() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const onFinish = (values) => {
    setError();
    setLoading(true);
    createIngredient(getFamilyId(), values).then((response) => {
      setLoading(false);
      if (!response.status) {
        navigate(`${basePath()}/ingredients/list`);
      }
      else {
        setError(response.data);
      }
    });
  };

  return (
    <>
      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          initialValue={location.search ? decodeURIComponent(location.search.replace('?name=', '')) : null}
          rules={[{ required: true, message: 'Please add a name!' }]}

        >
          <AutoFocusInput />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        {error && <Alert message={error} type="error" />}
      </Form>
    </>
  );
};