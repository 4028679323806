import "./Navigation.css";
import React from "react";
import { BrowserView, MobileView } from 'react-device-detect';
import DisplayBrowser from './DisplayBrowser';
import DisplayMobile from "./DisplayMobile";

export default function Navigation() {

  return (
    <>
      <BrowserView>
        <DisplayBrowser />
      </BrowserView>
      <MobileView>
        <DisplayMobile />
      </MobileView>
    </>
  );
};