import React from "react";
import { DisplayBrowser } from './DisplayBrowser';
import { BrowserView, MobileView } from 'react-device-detect';
import ListLayout from "../../../components/layouts/ListLayout";
import { DisplayMobile } from "./DisplayMobile";

export default function IngredientsList() {

  return (
    <>
      <BrowserView>
        <ListLayout
          children={<DisplayBrowser />}
        />
      </BrowserView>
      <MobileView>
        <ListLayout
          children={<DisplayMobile />}
        />
      </MobileView>
    </>
  );
};