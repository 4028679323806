import './List.css';
import React from "react";
import { DisplayBrowser } from './DisplayBrowser';
import { BrowserView, MobileView } from 'react-device-detect';
import { DisplayMobile } from "./DisplayMobile";

const List = React.memo(() =>
  (<>
    <BrowserView>
      <DisplayBrowser />
    </BrowserView>
    <MobileView>
      <DisplayMobile />
    </MobileView>
  </>))
  ;

export default List;