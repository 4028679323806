import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Alert, Space, Row, Checkbox } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getImageJobType, updateImageJobType } from '../../../api/nomnom/Admin/ImageJobType';
import { basePath } from '../../../helpers/urlHelper';
import { AutoFocusInput } from '../../../components/Form/AutoFocusInput';
import Loading from '../../../components/loading';
import { convertUtcToLocal } from '../../../helpers/dateHelper';

export const EditImageJobType = React.memo(() => {
  const params = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    getImageJobType(params.imageJobTypeId).then((resp) => { setData(resp); setLoading(false); });
  }, [params.imageJobTypeId]);

  const onFinish = (values) => {
    setError();
    setLoading(true);
    updateImageJobType(values).then((response) => {
      setLoading(false);
      if (!response.status) {
        navigate(`${basePath()}/admin/image-job-types`);
      }
      else {
        setError(response.data);
      }
    });
  };

  return (
    <Loading isLoading={loading}>
      <Form
        name="basic"
        onFinish={onFinish}
        initialValues={data}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please add a name!' }]}

        >
          <AutoFocusInput />
        </Form.Item>
        <Form.Item
          name="id"
          hidden
        />
        <Form.Item
          name="type"
          initialValue={"0"}
          hidden
        />
        <Form.Item
          label="Prompt Text"
          name="promptText"
          rules={[{ required: true, message: 'Please add prompt text!' }]}

        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Enabled?"
          name="isEnabled"
          valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
            <Button onClick={() => navigate('../')}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
        <Form.Item>
          <Space direction="vertical">
            <Row>
              Created: {convertUtcToLocal(data.createdDate)}
            </Row>
            <Row>
              Last Modified: {convertUtcToLocal(data.modifiedDate)}
            </Row>
          </Space>
        </Form.Item>
        {error && <Alert message={error} type="error" />}
      </Form>
    </Loading>
  );
});