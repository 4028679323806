import { useQuery } from 'react-query';
import { getArtifactVersions } from './Admin';

const fetch = async () => {
  const response = await getArtifactVersions();
  return response;
};


export const useFetchArtifactVersions = () =>
  useQuery({
    queryKey: ['system-verison'],
    queryFn: () => fetch()
  });