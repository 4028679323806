import React from 'react';
import { Outlet } from "react-router-dom";
import { Layout } from 'antd';
import Navigation from './Navigation';

export default function MainLayout() {

  const { Header } = Layout;

  return (
    <Layout>
      <Header className="header" style={{ minHeight: "8vh" }}>
        <Navigation />
      </Header>
      <Layout style={{ minHeight: "91vh" }}>
        <Outlet />
      </Layout>
    </Layout>
  );
};