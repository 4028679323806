import { useQuery } from 'react-query';
import { createdDaily } from '.';


const fetch = async (type, dateSlice) => {
  const response = await createdDaily(type, dateSlice);
  return response;
};


export const useFetchIngredientsCreatedReporting = (type, dateSlice) =>
  useQuery({
    queryKey: ['admin-report-ingredient', type, dateSlice],
    queryFn: () => fetch(type, dateSlice)
  });