import React, { useState } from "react";
import { Form, Button } from "antd";
import { Stepper } from 'antd-mobile';
import TagSelector from "../../../components/tags/TagSelector";

export const DisplayBrowser = React.memo(({ shoppingListIngredient, updateShoppingListIngredient, tagOptions }) => {
  let [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    const tag = values.selectedTag ? { id: (values.selectedTag ? values.selectedTag[0] : 0), name: "a name" } : null;
    setLoading(true);
    updateShoppingListIngredient({ ...values, tag });

  };
  return (

    <Form
      name="basic"
      initialValues={{ ...shoppingListIngredient, selectedTag: [shoppingListIngredient?.tag?.id] }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <h1>{shoppingListIngredient.name}</h1>
      <Form.Item
        name="id"
        hidden
      />
      <Form.Item
        name="ingredientId"
        hidden
      />
      <Form.Item
        name="isComplete"
        hidden
      />
      <Form.Item
        label="Quantity"
        name="quantity"
      >
        <Stepper />
      </Form.Item>
      <TagSelector tagOptions={tagOptions} ingredientId={shoppingListIngredient.ingredientId} />
      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form >
  );
});