import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import List from './List';
import { getFamilies } from '../../../api/nomnom/Admin/family';
import Loading from '../../../components/loading';

export default function Families() {
  let [data, setData] = useState({ items: [] });
  let [isLoading, setLoading] = useState(false);



  useEffect(() => {
    const loadData = () => {
      setLoading(true);
      getFamilies(1, 20).then((resp) => {
        setData(resp);
        setLoading(false);
      });
    };

    loadData();
  }, []);


  return (
    <><h2>Families</h2>
      <Loading isLoading={isLoading}>
        <List data={data.items} />
        <Outlet />
      </Loading>
    </>
  );
};