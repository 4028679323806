import React from "react";
import { List, SwipeAction } from 'antd-mobile';
import { toTitleCase } from "../../../helpers/stringHelper";
import { useNavigate } from "react-router";
import { basePath } from "../../../helpers/urlHelper";
import { Tag } from "antd";
import useTheme from "../../../hooks/useTheme";

export function DisplayMobile({ items, toggleCompleteIngredient }) {
  const navigate = useNavigate();
  const [darkMode] = useTheme();

  const rightActions = [
    {
      key: 'toggleComplete',
      text: 'Cross Off',
      color: 'success',
    },
  ];

  const onActionsReveal = (side, item) => {
    if (side === "right") {//time to toggle
      toggleCompleteIngredient(item);
    }
  };

  return (
    <>
      {Object.keys(items).map(key =>
        items[key].filter(a => !a.isCompleted).length > 0 &&
        <List
          key={`completed-list-${key}`}
          className={darkMode ? "dark-shopping-list" : "light-shopping-list"}
          size="medium"
          header={toTitleCase(key)}
          bordered
        >
          {items[key].map(item => (
            !item.isCompleted &&
            <SwipeAction
              key={item.key}
              rightActions={rightActions}
              onActionsReveal={(side) => onActionsReveal(side, item)}
            >
              <List.Item
                key={`list-item-${key}`}
                onClick={() => { navigate(`${basePath()}/list/${item.key}`); }}
              >
                {item.name} <Tag>{item.quantity}</Tag>
              </List.Item>
            </SwipeAction>
          ))}
        </List>
      )}
    </>
  );
};