import React, { useState } from 'react';
import { Button, Form, Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import { createRecipeImport } from '../../../api/nomnom/Family/recipeImport';
import { getFamilyId } from '../../../helpers/localstorage';
import { AutoFocusInput } from '../../../components/Form/AutoFocusInput';

export default function Add() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const onFinish = (values) => {
    setError();
    setLoading(true);
    createRecipeImport(getFamilyId(), values).then((response) => {
      setLoading(false);
      if (response.id) {
        navigate(`../${response.id}`);
      }
      else {
        setError(response.data);
      }
    });
  };

  return (
    <>
      <h2>Create Recipe Import</h2>
      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Url"
          name="url"
          rules={[{ required: true, message: 'Please add a URL!' }]}

        >
          <AutoFocusInput />
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
        {error && <Alert message={error} type="error" />}
      </Form>
    </>
  );
};