import { NOMNOM_URL } from "../../config";
import axios from "axios";
import { put, get } from './index';

export const getFamilyUsers = (familyId) => {
  return get(`family/${familyId}/users`);
};

export const login = (username, password) => {
  return axios
    .post(`${NOMNOM_URL}account`, { username, password })
    .then(response => response.data);
};

export const createUser = (username, password, familyCode) => {
  return put(`account`, { username, password, familyCode });
};