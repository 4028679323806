import { get, post, del, put } from '../index';

export const getTagCategories = (familyId, page, size) => {
  return get(`family/${familyId}/tag-category/?page=${page}&size=${size}`);
};

export const createTagCategory = (familyId, request) => {
  return post(`family/${familyId}/tag-category`, request);
};

export const deleteTagCategory = (familyId, id) => {
  return del(`family/${familyId}/tag-category/${id}`);
};

export const updateTagCategory = (familyId, request) => {
  return put(`family/${familyId}/tag-category`, request);
};

export const getTagCategory = (familyId, id) => {
  return get(`family/${familyId}/tag-category/${id}`);
};