import React from 'react';
import { Tag, Input, Tooltip, AutoComplete } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './EditableTagGroup.css';
import { addTag as addIngredientTag, deleteTag as deleteIngredientTag } from '../../api/nomnom/Family/ingredient';
import { addTag as addRecipeTag, deleteTag as deleteRecipeTag } from '../../api/nomnom/Family/recipe';
import { getFamilyId } from '../../helpers/localstorage';
import { search } from '../../api/nomnom/Tag/Tag';

class EditableTagGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: props.tags,
      inputVisible: false,
      inputValue: '',
      options: []
    };
  }
  state = {
  };

  handleClose = removedTag => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    switch (this.props.entityType?.toLowerCase()) {
    case "ingredient":
      deleteIngredientTag(getFamilyId(), this.props.entityId, removedTag.id).then(() => {
        this.setState({ tags });
      });
      break;
    case "recipe":
      deleteRecipeTag(getFamilyId(), this.props.entityId, removedTag.id).then(() => {
        this.setState({ tags });
      });
      break;
    default:
      // eslint-disable-next-line
      throw `unknown entity type ${this.props.entityType ?? "EMPTY"}`;
    }

  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;

    if (inputValue && tags.indexOf(inputValue) === -1) {
      switch (this.props.entityType?.toLowerCase()) {
      case "ingredient":
        addIngredientTag(getFamilyId(), this.props.entityId, { name: inputValue }).then(resp => {
          this.setState({
            tags: [...tags, resp],
            inputVisible: false,
            inputValue: '',
          });
        });
        break;
      case "recipe":
        addRecipeTag(getFamilyId(), this.props.entityId, { name: inputValue }).then(resp => {
          this.setState({
            tags: [...tags, resp],
            inputVisible: false,
            inputValue: '',
          });
        });
        break;
      default:
        // eslint-disable-next-line
        throw `unknown entity type ${this.props.entityType ?? "EMPTY"}`;
      }
    }
    else {
      this.setState({
        inputVisible: false,
        inputValue: ''
      });
    }
  };

  saveInputRef = input => {
    this.input = input;
  };

  onSelect = text => {
    this.setState({ inputValue: text });
  };

  handleSearch = value => {
    search(value).then(response => {
      this.setState({ options: response.map(item => { return { value: item }; }) });
    });
    this.setState({ inputValue: value });
  };

  render() {
    const { tags, inputVisible, inputValue } = this.state;
    const { isEditable } = this.props;

    return (
      <div className="tag-container">
        {tags.map((tag) => {
          const isLongTag = tag.name.length > 20;

          const tagElem = (
            <Tag
              className="edit-tag"
              key={tag.key}
              closable={isEditable}
              onClose={() => this.handleClose(tag)}
            >
              <span>
                {isLongTag ? `${tag.name.slice(0, 20)}...` : tag.name}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag.name} key={tag.key}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Tag key="tag-auto-complete">
            <AutoComplete
              options={this.state.options}
              onSelect={this.onSelect}
              onSearch={this.handleSearch}
            >
              <Input
                ref={this.saveInputRef}
                type="text"
                size="small"
                className="tag-input"
                value={inputValue}
                onBlur={this.handleInputConfirm}
                onPressEnter={this.handleInputConfirm}
                enterButton
              />
            </AutoComplete>
          </Tag>
        )}
        {!inputVisible && isEditable && (
          <Tag className="site-tag-plus" key="new-tag" onClick={this.showInput}>
            <PlusOutlined /> New Tag
          </Tag>
        )}
      </div>
    );
  }
}

export default EditableTagGroup;