import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Row, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { deleteTagCategory, getTagCategories } from "../../../api/nomnom/Family/tagCategories";
import { getFamilyId } from "../../../helpers/localstorage";

export default function List() {
  const navigate = useNavigate();
  let [data, setData] = useState([]);
  const [isDeleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    getTagCategories(getFamilyId(), 1, 100).then((resp) => { setData(resp.items); });
  }, [setData]);

  const confirm = (id) => {
    setDeleteLoading(true);
    deleteTagCategory(getFamilyId(), id).then(() => {
      setData(data.filter(a => a.id !== id));
    });
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: row => { return <Link to={`../${row.id}`}>{row.name}</Link>; }
    },
    {
      title: 'Tags',
      key: 'tags',
      render: row => { return <>{row.tags.length}</>; }
    },
    {
      title: '',
      key: 'delete',
      render: row => {
        return (row.name !== 'Default' && row.tags.length === 0 && <Popconfirm
          title="Are you sure?"
          onConfirm={() => confirm(row.id)}
          isLoading={isDeleteLoading}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>);
      }
    },
  ];

  return (
    <>
      <Row style={{ paddingBottom: "10px" }}>
        <Button type="primary" onClick={() => navigate(`../add`)}>
          Add
        </Button>
      </Row>
      <Table dataSource={data} columns={columns} />
    </>
  );
};