import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Row, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { deleteRecipeImport, search } from "../../../api/nomnom/Family/recipeImport";
import { getFamilyId } from "../../../helpers/localstorage";
import Loading from '../../../components/loading';

export default function List() {
  const navigate = useNavigate();
  let [data, setData] = useState([]);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    search(getFamilyId(), 1, 100).then((resp) => { setLoading(false); setData(resp.items); });
  }, [setData, setLoading]);

  const confirm = (id) => {
    setDeleteLoading(true);
    deleteRecipeImport(getFamilyId(), id).then(() => {
      setData(data.filter(a => a.id !== id));
    });
  };

  const columns = [
    {
      title: 'Url',
      key: 'url',
      render: row => { return <Link to={`../${row.id}`}>{row.url}</Link>; }
    },
    {
      title: 'Status',
      key: 'status',
      render: row => { return row.status; }
    },
    {
      title: '',
      key: 'delete',
      render: row => {
        return (<Popconfirm
          title="Are you sure?"
          onConfirm={() => confirm(row.id)}
          isLoading={isDeleteLoading}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>);
      }
    },
  ];

  return (
    <Loading isLoading={isLoading}>
      <Row style={{ paddingBottom: "10px" }}>
        <Button type="primary" onClick={() => navigate(`../add`)}>
          Add
        </Button>
      </Row>
      <Table dataSource={data} columns={columns} />
    </Loading>
  );
};