import React from "react";
import { Button, Row, Col, List, Tooltip, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

export function DisplayBrowser({ items, toggleCompleteIngredient }) {
  return (
    <List
      size="medium"
      header="Completed"
      bordered>
      {Object.keys(items).map(key =>
        items[key].filter(a => a.isCompleted).length > 0 &&
        items[key].filter(a => a.isCompleted).map(item =>
          (<List.Item key={item.key}
            className="list-strikethrough"
          >
            <List.Item.Meta
              title={<Row>
                <Col xs={{ span: 20 }}>
                  {item.name} ({item.quantity})
                </Col>
                <Col xs={{ span: 4 }} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Space>
                    <Tooltip placement="top" title="Undo">
                      <Button icon={<CheckOutlined />} onClick={() => toggleCompleteIngredient(item)} />
                    </Tooltip>
                  </Space>
                </Col>
              </Row>}
            />
          </List.Item>)
        )
      )}
    </List>
  );
};