import { get, post, del, put } from '../index';

export const search = (familyId, page, size, search) => {
  const searchQuery = search ? `search=${search}&` : '';
  return get(`family/${familyId}/ingredient/search?${searchQuery}page=${page}&size=${size}`);
};

export const createIngredient = (familyId, request) => {
  return post(`family/${familyId}/ingredient`, request);
};

export const deleteIngredient = (familyId, id) => {
  return del(`family/${familyId}/ingredient/${id}`);
};

export const updateIngredient = (familyId, request) => {
  return put(`family/${familyId}/ingredient`, request);
};

export const getIngredient = (familyId, ingredientId) => {
  return get(`family/${familyId}/ingredient/${ingredientId}`);
};

export const addTag = (familyId, ingredientId, request) => {
  return post(`family/${familyId}/ingredient/${ingredientId}/tag`, request);
};

export const deleteTag = (familyId, ingredientId, tagId) => {
  return del(`family/${familyId}/ingredient/${ingredientId}/tag/${tagId}`);
};