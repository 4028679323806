import React, { useState } from "react";
import { Tag, FloatButton, Modal, Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getFamilyId, getShoppingListId } from "../../helpers/localstorage";
import { search as searchIngredient } from '../../api/nomnom/Family/ingredient';
import { createIngredient } from '../../api/nomnom/Family/ingredient';
import { useSignalR } from '../../contexts/SignalRContext';
import { AutoFocusAutoComplete } from "../../components/Form/AutoFocusAutoComplete";
import * as signalRCommands from "../../constants/signalRCommands";

export const AddItemModal = React.memo(({ onClose, shouldFloat }) => {
  const [open, setOpen] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { postCommand } = useSignalR();
  const [form] = Form.useForm();

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = (values) => {
    setConfirmLoading(true);
    let maybeCreateIngredient = new Promise((resolve) => resolve(true));
    if (values.ingredient.id === 0) {
      maybeCreateIngredient = new Promise((resolve) => {
        resolve(createIngredient(getFamilyId(), { ...values.ingredient, familyId: getFamilyId() }).then((response) => {
          values.ingredient = response;
          values.ingredientId = response.id;
        }));
      });
    }

    maybeCreateIngredient.then(() => {
      postCommand(signalRCommands.UPDATE_SHOPPING_LIST_INGREDIENT, getFamilyId(), getShoppingListId(), values);
      form.resetFields();
      onClose();
      setConfirmLoading(false);
      setOpen(false);
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  const autocompleteIngredientDisplay = (ingredient) => ({
    value: ingredient.name,
    ingredient: ingredient,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span>
          {ingredient.name}
        </span>
        {ingredient.tags && <span>{ingredient.tags.map(tag => <Tag>{tag.name}</Tag>)}</span>}
      </div>
    ),
  });

  const onSearchIngredient = (searchText) => {
    form.setFieldValue("ingredient", { id: 0, name: searchText });
    form.setFieldValue("ingredientId", 0);

    searchIngredient(getFamilyId(), 1, 5, searchText).then(resp => {
      setAutocompleteOptions(
        resp.items.map(ingredient => { return autocompleteIngredientDisplay(ingredient); })
      );
    });
  };

  const onSelectIngredient = (data, option) => {
    form.setFieldValue("ingredient", option.ingredient);
    form.setFieldValue("ingredientId", option.ingredient?.id ?? 0);
    form.validateFields().then((values) => {
      handleOk(values);
    });
  };

  return (
    <>
      {shouldFloat === true &&
      <FloatButton
        icon={<PlusOutlined />}
        type="primary"
        tooltip={<div>Add to list</div>}
        style={{ right: 48 }}
        onClick={showModal}
      />
      }
      {shouldFloat !== true && <Button
        type="dashed"
        onClick={showModal}
        icon={<PlusOutlined />}>
        Add to Shopping List
      </Button>}

      <Modal
        title="Add Ingredient"
        open={open}
        destroyOnClose={true}
        onOk={() => {
          form.validateFields().then((values) => {
            handleOk(values);
          });
        }}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
          name="addItemModal"
        >
          <Form.Item
            name="id"
            initialValue={0}
            hidden
          />
          <Form.Item
            name="familyId"
            initialValue={getFamilyId()}
            hidden
          />
          <Form.Item
            name="quantity"
            initialValue={1}
            hidden
          />

          <Form.Item
            name="ingredient"
            hidden />
          <Form.Item
            name="ingredientId"
            hidden />

          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input the ingredient name.',
              },
            ]}
          >
            <AutoFocusAutoComplete
              options={autocompleteOptions}
              allowClear={true}
              style={{ width: "100%" }}
              onSelect={onSelectIngredient}
              onSearch={onSearchIngredient}
              placeholder="Search Ingredients"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});