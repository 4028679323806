import React from "react";
import { DotLoading } from "antd-mobile";

export const InfiniteScrollContent = ({ hasMore }) => {
  return (
    <>
      {hasMore ? (
        <>
          <span>Loading</span>
          <DotLoading />
        </>
      ) : (
        <span style={{ fontSize: 20 }}>🍩</span>
      )}
    </>
  );
};