import React from 'react';
import { PullToRefresh } from 'antd-mobile';
import { useNavigate } from 'react-router';
import { ReloadOutlined, DownCircleFilled } from '@ant-design/icons';
import useTheme from '../hooks/useTheme';

export const Puller = ({ children, onRefresh }) => {
  const navigate = useNavigate();
  const [darkMode] = useTheme();

  const color = darkMode ? "white" : "black";
  const iconStyle = { fontSize: 20, color: color };
  return (

    <PullToRefresh
      threshold={40}
      pullingText={<DownCircleFilled style={iconStyle} />}
      canReleaseText={<ReloadOutlined style={iconStyle} />}
      completeText={<ReloadOutlined spin={true} style={iconStyle} />}
      onRefresh={async () => {
        if (onRefresh) {
          onRefresh();
        }
        else {
          navigate(0);
        }
      }}
    >
      {children}
    </PullToRefresh>
  );
};