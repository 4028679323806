import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Popconfirm, Image, Space } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { deleteRecipeImage, getRecipeImages, regenerateImages } from '../../api/nomnom/Family/recipe';
import { getFamilyId } from '../../helpers/localstorage';
import { NOMNOM_URL } from '../../config';

export default function Images({ recipeId }) {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  useEffect(() => {
    setLoading(true);
    getRecipeImages(getFamilyId(), recipeId, 1, 20).then(resp => {
      setImages(resp.items);
      setLoading(false);
    });
  }, [recipeId]);

  const regenerate = async () => {
    setLoading(true);
    await regenerateImages(getFamilyId(), recipeId);
    setLoading(false);
  };

  const confirmDelete = async (imageId) => {
    await deleteRecipeImage(getFamilyId(), recipeId, imageId);
    setImages([...images.filter(a => a.id !== imageId)]);
  };
  return (
    <>
      <Row>
        <Image.PreviewGroup
          preview={{
            onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
          }}
        >
          {images && images.map(image => (<><Image
            width={200}
            src={`${NOMNOM_URL}image/${image.id}/thumbnail`}
            preview={{
              src: `${NOMNOM_URL}image/${image.id}`,
            }}
          />
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => confirmDelete(image.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
          </>))}
        </Image.PreviewGroup >
      </Row>
      {/* <Row gutter={16}>
        {images && images?.map((image) => (
          <Col key={image.id} span={2}>
            <Image imageId={image.id} />
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => confirmDelete(image.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          </Col>
        ))}
      </Row> */}
      <Button loading={loading} onClick={regenerate}>Regenerate Images</Button>
    </>
  );
};