import "./login.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col, Input, Button, Card, message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { login } from "../../api/nomnom/account";
import { getFamilies } from "../../api/nomnom/UserProfile/userprofile";
import { getUserId } from "../../helpers/jwt";
import { getFamily, getFamilyId, setFamily, setJwtToStorage, setShoppingList } from "../../helpers/localstorage";
import { basePath } from "../../helpers/urlHelper";
import { getDefaultShoppingList } from "../../api/nomnom/Family/shoppingList";
import { useSignalR } from "../../contexts/SignalRContext";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { setForceEffect } = useSignalR();
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = (values) => {
    setLoading(true);
    login(values.username, values.password).then((data) => {
      if (data) {
        setJwtToStorage(data.accessToken);

        getFamilies(getUserId()).then((data) => {
          //pick first family if there isn't one already in storage
          if (!getFamily()) {
            setFamily(data[0]);
          }

          getDefaultShoppingList(getFamilyId()).then((data) => {
            setShoppingList(data);
            setForceEffect(new Date());
            const origin = location.state?.from?.pathname || `${basePath()}/recipes/list`;
            navigate(origin);
          });
        });
      }
      else {
        setLoading(false);
        messageApi.open({
          type: 'error',
          content: 'Failed to login',
        });
      }

    }, () => { setLoading(false); });
  };

  return (
    <>
      {contextHolder}
      <Card>
        <Form
          name="login"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 4 }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >

          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Username is required',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Password is required.',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 9,
              span: 5,
            }}
          >
            <Row>
              <Col md={{ span: 12 }} sm={{ span: 2 }} align="right">
                <Button type="primary" htmlType="submit" loading={loading}>
                  Login
                </Button>
              </Col>
              <Col md={{ span: 12 }} sm={{ span: 12 }}>
                <Link to={`${basePath("login/forgot")}`}>
                  <Button type="link">Forgot your password?
                  </Button>
                </Link>
              </Col>
            </Row>
          </Form.Item>
        </Form>

        <div className="signup">
          New to Nomnom?
          <Link to={`${basePath("login/signup")}`}>
            <Button type="link">Sign up</Button>
          </Link>
        </div>
      </Card>
    </>
  );
};