import { useQuery } from 'react-query';
import { getUserId } from '../../../helpers/jwt';
import { getUserProfile } from './userprofile';

const fetch = async () => {
  const response = await getUserProfile(getUserId());
  return response;
};


export const useFetchUserProfile = () =>
  useQuery({
    queryKey: ['user-profile'],
    queryFn: () => fetch()
  });