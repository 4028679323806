import React, { useState } from 'react';
import { Button, Form, Input, Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import { createImageJobType } from '../../../api/nomnom/Admin/ImageJobType';
import { basePath } from '../../../helpers/urlHelper';
import { AutoFocusInput } from '../../../components/Form/AutoFocusInput';

export const AddImageJobType = React.memo(() => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const onFinish = (values) => {
    setError();
    setLoading(true);
    createImageJobType(values).then((response) => {
      setLoading(false);
      if (!response.status) {
        navigate(`${basePath()}/admin/image-job-types`);
      }
      else {
        setError(response.data);
      }
    });
  };

  return (
    <>
      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please add a name!' }]}

        >
          <AutoFocusInput />
        </Form.Item>
        <Form.Item
          name="id"
          initialValue={"0"}
          hidden
        />
        <Form.Item
          label="Prompt Text"
          name="PromptText"
          rules={[{ required: true, message: 'Please add prompt text!' }]}

        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        {error && <Alert message={error} type="error" />}
      </Form>
    </>
  );
});