import React, { useState, useEffect } from "react";
import { Row, Col, Card, Layout, Button, Popconfirm } from 'antd';
import { getAllImageJobType, deleteImageJobType } from '../../../api/nomnom/Admin/ImageJobType';
import Loading from '../../../components/loading';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { basePath } from "../../../helpers/urlHelper";
import { getTimeIntervalOrDate } from "../../../helpers/dateHelper";

export const ImageJobTypes = React.memo(() => {
  const navigate = useNavigate();
  const { Content } = Layout;
  const [data, setData] = useState({
    items: [],
    currentPage: 1,
    pageSize: 20,
    hasNext: true,
    isLoading: true,
    isDeleteLoading: false
  });
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const loadData = (currentPage, pageSize, append = true) => {
    getAllImageJobType(currentPage, pageSize).then((resp) => {
      if (append) {
        setData({ ...resp, items: [...data.items, ...resp.items] });
      }
      else if (resp) {
        setData(resp);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData(1, data.pageSize, null, false);
    // eslint-disable-next-line
  }, []);

  const onFinish = (values) => {
    navigate(`${basePath()}/admin/image-job-types/add`);
  };

  const onDelete = (id) => {
    setDeleteLoading(true);
    deleteImageJobType(id).then(() => {
      loadData(data.currentPage, data.pageSize, null, false);
      setDeleteLoading();
    });
  };

  return (
    <Loading isLoading={isLoading}>
      <Layout>
        <Content>
          <Row style={{ paddingLeft: "4em", paddingBottom: "10px" }}>
            <Col>
              <h2 style={{ margin: 0 }}>Image Job Types</h2>
            </Col>
            <Col push={19}>
              <Button type="primary" onClick={onFinish} icon={<PlusOutlined />}>Add</Button>
            </Col>
          </Row>
          <InfiniteScroll
            style={{ overflow: 'none' }}
            dataLength={data.items ? data.items.length : 20} //This is important field to render the next data
            next={() => loadData(data.currentPage + 1, data.pageSize)}
            hasMore={data.hasNext}
            loader={<h4>Loading...</h4>}>
            <Row gutter={[16, 24]}>
              {data.items && data.items.map(item => (
                <Col offset={1} span={23} key={`col-${item.id}`}>
                  <Card
                    title={item.name + (item.isEnabled ? "" : " (Disabled)")}
                    alt={item.name}
                    extra={"Created: " + getTimeIntervalOrDate(item.createdDate)}
                    className="recipe-card"
                    actions={[
                      <Link to={`./${item.id}`}>
                        <EditOutlined key={`edit-${item.id}`} />
                      </Link>,
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => onDelete(item.id)}
                        isLoading={isDeleteLoading}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined key={`delete-${item.id}`} />
                      </Popconfirm>
                    ]}>
                    <Row>
                      {item.promptText}
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
        </Content>
      </Layout>
    </Loading >
  );
});