import { del, get, post, put } from '../index';

export const getFamily = (familyId) => {
  return get(`family/${familyId}`);
};

export const getFamilyUsers = (familyId) => {
  return get(`family/${familyId}/users`);
};

export const createFamily = (createFamilyRequest) => {
  return post('family', createFamilyRequest);
};

export const updateFamily = (updateRequest) => {
  return put(`family/${updateRequest.id}`, updateRequest);
};

export const deleteFamily = (familyId) => {
  return del(`family/${familyId}`);
};

export const removeUserFromFamily = (familyId, userId) => {
  return del(`family/${familyId}/users/${userId}`);
};