import { useQuery } from 'react-query';
import { getFamily } from './family';

const fetch = async (familyId) => {
  const response = await getFamily(familyId);
  return response;
};


export const useFetchFamily = (familyId) =>
  useQuery({
    queryKey: ['family', familyId],
    queryFn: () => fetch(familyId)
  });