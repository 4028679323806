import React, { useState, useEffect } from "react";
import { BrowserView, MobileView } from 'react-device-detect';
import { useNavigate, useParams } from "react-router";
import Loading from "../../../components/loading";
import { getFamilyId } from "../../../helpers/localstorage";
import { DisplayBrowser } from "./DisplayBrowser";
import { DisplayMobile } from "./DisplayMobile";
import { getTagCategories } from "../../../api/nomnom/Family/tagCategories";
import { getIngredient, updateIngredient } from "../../../api/nomnom/Family/ingredient";
import { basePath } from "../../../helpers/urlHelper";

export default function Edit() {
  let params = useParams();
  let [data, setData] = useState({});
  let [tags, setTags] = useState([]);
  let [isLoading, setLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    getTagCategories(getFamilyId(), 1, 100).then(resp => setTags(resp.items[0].tags));
    getIngredient(getFamilyId(), params.ingredientId).then((resp) => {
      setData(resp);
      setLoading(false);
    });
  }, [params.ingredientId]);

  const save = (values) => {
    setLoading(true);
    updateIngredient(getFamilyId(), values).then((response) => {
      setLoading(false);
      navigate(`${basePath()}/ingredients/list`);
    });
  };

  return (
    <Loading isLoading={isLoading}>
      <BrowserView>
        <DisplayBrowser
          item={data}
          save={save}
          tagOptions={tags} />
      </BrowserView>
      <MobileView>
        <DisplayMobile
          item={data}
          save={save}
          tagOptions={tags} />
      </MobileView>
    </Loading>
  );
};