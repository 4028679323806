import './recipeFront.css';
import React from "react";
import { DisplayBrowser } from './DisplayBrowser';
import { BrowserView, MobileView } from 'react-device-detect';
import { DisplayMobile } from "./DisplayMobile";

const RecipeFront = React.memo(({ recipe }) =>
  (<>
    <BrowserView>
      <DisplayBrowser recipe={recipe} />
    </BrowserView>
    <MobileView>
      <DisplayMobile recipe={recipe} />
    </MobileView>
  </>));

export default RecipeFront;