import { ADMIN } from '../constants/roles';
import { getJwtFromStorage } from './localstorage';

export const parseJwt = (token) => {
  if (!token) return null;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const isInRole = (role) => {
  return isInRoles([role]);
};
export const isInRoles = (roles) => {
  const parsedToken = parseJwt(getJwtFromStorage());
  if (roles && parsedToken && parsedToken.role) {
    if (parsedToken.role.indexOf(ADMIN) !== -1) return true;

    return roles.some(r => parsedToken.role.includes(r));
  }
  return false;
};

export const getUserId = () => {
  return parseJwt(getJwtFromStorage())?.sub;
};