import React, { useLayoutEffect, useState } from 'react';
import { ConfigProvider, theme } from 'antd';
import './App.css';
import MainLayout from '../layouts/MainLayout';
import useTheme from "../../hooks/useTheme";
import { TitleContext } from '../../helpers/titleHelper';
import { QueryClient, QueryClientProvider } from 'react-query';

export default function App() {
  const [darkMode] = useTheme();
  const [title, setTitle] = useState("Nomnom");
  const queryClient = new QueryClient();

  useLayoutEffect(() => {
    document.documentElement.setAttribute(
      'data-prefers-color-scheme',
      darkMode ? 'dark' : 'light'
    );
  }, [darkMode]);

  return (
    <QueryClientProvider client={queryClient}>
      <TitleContext.Provider value={{ title, setTitle }}>
        <ConfigProvider
          theme={{
            algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
          }}
        >
          <MainLayout />
        </ConfigProvider>
      </TitleContext.Provider>
    </QueryClientProvider>
  );
}