export const localStorageKeys = {
  family: "family",
  jwt: "nomnomJwt",
  shoppingList: "shoppingList"
};
export const setFamily = (family: object) => family && localStorage.setItem(localStorageKeys.family, JSON.stringify(family));
export const getFamily = () => {
  const value = localStorage.getItem(localStorageKeys.family);
  return value && value !== "undefined" ? JSON.parse(value) : null;
};
export const getFamilyId = () => getFamily()?.id ?? 0;
export const getJwtFromStorage = () => localStorage.getItem(localStorageKeys.jwt)!;
export const setJwtToStorage = (value: string) => localStorage.setItem(localStorageKeys.jwt, value);
export const clearLocalStorage = () => {
  localStorage.removeItem(localStorageKeys.jwt);
  localStorage.removeItem(localStorageKeys.family);
  localStorage.removeItem(localStorageKeys.shoppingList);
};

export const setShoppingList = (shoppingList: object) => localStorage.setItem(localStorageKeys.shoppingList, JSON.stringify(shoppingList));
export const getShoppingList = () => JSON.parse(localStorage.getItem(localStorageKeys.family)!);
export const getShoppingListId = () => getShoppingList()?.id ?? 0;