import React from "react";
import { List, SwipeAction } from 'antd-mobile';
import useTheme from "../../../hooks/useTheme";

export function DisplayMobile({ items, toggleCompleteIngredient }) {
  const [darkMode] = useTheme();

  const rightActions = [
    {
      key: 'toggleComplete',
      text: 'Undo',
      color: 'warning',
    },
  ];

  const onActionsReveal = (side, item) => {
    if (side === "right") {//time to toggle
      toggleCompleteIngredient(item);
    }
  };

  return (
    <List
      size="medium"
      header="Completed"
      className={darkMode ? "dark-shopping-list" : "light-shopping-list"}
      bordered
    >
      {Object.keys(items).map(key =>
        items[key].filter(a => a.isCompleted).length > 0 &&
        items[key].map(item =>
          item.isCompleted &&
          <SwipeAction
            key={item.key}
            rightActions={rightActions}
            onActionsReveal={(side) => onActionsReveal(side, item)}
          >
            <List.Item
              key={item.key}
              className="list-strikethrough"
            >
              {item.name}
            </List.Item>
          </SwipeAction>
        ))}
    </List>
  );
};