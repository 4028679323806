import React from "react";
import { Button, Row, Col, List, Tooltip, Space } from 'antd';
import { CloseOutlined, FormOutlined } from '@ant-design/icons';
import { toTitleCase } from "../../../helpers/stringHelper";
import { basePath } from "../../../helpers/urlHelper";
import { Link } from "react-router-dom";
import useTheme from "../../../hooks/useTheme";

export function DisplayBrowser({ items, toggleCompleteIngredient }) {
  const [darkMode] = useTheme();
  return (
    <>
      {Object.keys(items).map(key => (
        items[key].filter(a => !a.isCompleted).length > 0 && <List
          className={darkMode ? "dark-shopping-list" : "light-shopping-list"}
          key={`pending-list-${key}`}
          size="medium"
          bordered
          header={toTitleCase(key)}
          dataSource={items[key].filter(a => !a.isCompleted)}
          local={{ emptyText: "Empty List" }}
          renderItem={(item) => (
            <Row key={item.key}>
              <Col span={20}>
                {item.name} ({item.quantity})
              </Col>
              <Col span={4} style={{ display: "flex", justifyContent: "flex-end" }} >
                <Space>
                  <Tooltip title="Edit">
                    <Link to={`${basePath()}/list/${item.key}`}>
                      <Button icon={<FormOutlined />} />
                    </Link>
                  </Tooltip>
                  <Tooltip placement="top" title="Cross off">
                    <Button icon={<CloseOutlined />} onClick={() => toggleCompleteIngredient(item)} />
                  </Tooltip>
                </Space>
              </Col>
            </Row>)}
        />
      ))}
    </>
  );
};