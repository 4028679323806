import './RecipeImport.css';
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from '../../../components/loading';
import { getRecipeImport, finalizeRecipeImport } from "../../../api/nomnom/Family/recipeImport";
import { getFamilyId } from "../../../helpers/localstorage";
import { MinusCircleOutlined } from "@ant-design/icons";
import { Row, Col, Form, Input, Button, Space, Tooltip } from 'antd';
import { basePath } from '../../../helpers/urlHelper';

export default function RecipeImport() {

  let params = useParams();
  let [data, setData] = useState();
  let [isLoading, setLoading] = useState(true);
  let [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();


  useEffect(() => {
    getRecipeImport(getFamilyId(), params.recipeImportId).then((resp) => {
      setData(resp);
      if (resp.recipeContent) {
        const content = JSON.parse(resp.recipeContent);
        form.setFieldsValue({
          familyId: getFamilyId(),
          recipeTitle: content.recipeTitle,
          detailedInstructions: content.detailedInstructions,
          ingredients: content.ingredients,
          servingSize: content.servingSize
        });
      }
      setLoading(false);
    });
  }, [params.recipeImportId, setData, setLoading, form]);

  const updateContent = (property) => {
    switch (property) {
    case "delete-directions":
      form.setFieldsValue({ detailedInstructions: [] });
      break;
    case "delete-ingredients":
      form.setFieldsValue({ ingredients: [] });
      break;
    default:
      break;
    }
  };

  const onFormSubmit = (values) => {
    setIsSubmitting(true);
    finalizeRecipeImport(values.familyId, params.recipeImportId, values).then((response) => {
      setIsSubmitting(false);
      navigate(`${basePath()}/recipes/${response.recipe.id}`);
    });
  };

  return (
    <Loading isLoading={isLoading}>
      <>
        {data && <>
          <h2><a href={data.url} target="_new">{data.url}</a></h2>
          {data.recipeContent && <>
            <Form
              name="basic"
              onFinish={onFormSubmit}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                name="id"
                hidden
              />
              <Form.Item
                name="familyId"
                hidden
              />

              <Row>
                <Col span={15} style={{ paddingRight: "1em" }}>
                  <h1>Name</h1>
                  <Form.Item name="recipeTitle">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <h1>Servings</h1>
                  <Form.Item name="servingSize">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              {/* <h1>Description</h1>
              <Row>
                <Col span={24}>
                  <Form.Item name="description">
                    <Input.TextArea rows={13} />
                  </Form.Item>
                </Col>
              </Row> */}

              <Row>
                <Space>
                  <h1>Ingredients</h1>
                  <Tooltip title="Delete all ingredients">
                    <Button icon={<MinusCircleOutlined />} onClick={() => updateContent("delete-ingredients")} />
                  </Tooltip>
                </Space>
              </Row>
              <Form.List
                name="ingredients">
                {(fields, { remove }) => (
                  <>
                    {fields.map((key, name) => (
                      <Row key={`ingredients-list-${key.name}`}>
                        <Col span={1}>
                          <Button icon={<MinusCircleOutlined />} onClick={() => remove(key.name)} />
                        </Col>
                        <Col span={2} style={{ paddingRight: "1em" }}>
                          <Form.Item name={[name, "amount"]}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={4} style={{ paddingRight: "1em" }}>
                          <Form.Item name={[name, "unit"]}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item name={[name, "name"]}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>

              <Row>
                <Space>
                  <h1>Directions</h1>
                  <Tooltip title="Delete all directions" onClick={() => updateContent("delete-directions")}>
                    <Button icon={<MinusCircleOutlined />} />
                  </Tooltip>
                </Space>
              </Row>
              <Form.List
                name="detailedInstructions">
                {(fields, { remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row key={`instructions-list-${index}`} style={{ paddingBottom: "1em" }}>
                        <Col span={1}>
                          <Button icon={<MinusCircleOutlined />} onClick={() => remove(field.name)} />
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            {...field}
                            noStyle
                          >
                            <Input.TextArea />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))
                    }
                  </>)
                }
              </Form.List>

              <Row style={{ paddingTop: "1em" }}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isSubmitting}>
                    Create Recipe
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </>}
        </>
        }
        {/* {data && data.errorResponse && <>
          <Descriptions title="Debug Info">
            <Descriptions.Item label="Error Rsponse">{data.errorResponse}</Descriptions.Item>
            <Descriptions.Item label="Raw Response">{data.rawResponse}</Descriptions.Item>
          </Descriptions>
          <Descriptions title="Prompt Text">
            <Descriptions.Item>
              <div dangerouslySetInnerHTML={{ __html: data.promptText.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
            </Descriptions.Item>
          </Descriptions>
        </>
        } */}
      </>
    </Loading >
  );
}