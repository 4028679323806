import React from "react";
import { Link } from 'react-router-dom';
import SubLayout from '../../components/layouts/SubLayout';
import { Menu } from 'antd';
import { basePath } from "../../helpers/urlHelper";

export default function UserProfile() {
  const subMenu = (<>
    <Menu.Item key="general">
      <Link to={basePath("userprofile/general")} >
        General
      </Link>
    </Menu.Item>
    <Menu.Item key="families">
      <Link to={basePath("userprofile/families")}>
        Families
      </Link>
    </Menu.Item>
  </>);
  return (
    <>
      <SubLayout
        subMenu={subMenu}
        title="User Profile"
        defaultSelectedKeys={['1']}
      />
    </>
  );
};