import { get, del, put } from '../index';

export const getShoppingListIngredients = (familyId, shoppingListId, page, size) => {
  return get(`family/${familyId}/shopping-list/${shoppingListId}/ingredient?page=${page}&size=${size}`);
};

export const getShoppingListIngredient = (familyId, shoppingListId, shoppingListIngredientId) => {
  return get(`family/${familyId}/shopping-list/${shoppingListId}/ingredient/${shoppingListIngredientId}`);
};

export const updateShoppingListIngredient = (familyId, shoppingListId, shoppingListIngredient) => {
  return put(`family/${familyId}/shopping-list/${shoppingListId}/ingredient`, shoppingListIngredient);
};

export const deleteShoppingListIngredient = (familyId, shoppingListId, shoppingListIngredientId) => {
  return del(`family/${familyId}/shopping-list/${shoppingListId}/ingredient/${shoppingListIngredientId}`);
};