import React from "react";
import { Button } from 'antd';
import { Form, Input } from "antd-mobile";
import TagSelector from '../../../components/tags/TagSelector';

export const DisplayMobile = React.memo(({ item, save, tagOptions }) => {
  const onFinish = (values) => {
    values.tags = [values.selectedTag ? { id: (values.selectedTag ? values.selectedTag[0] : 0), name: "a name" } : null];
    save(values);
  };
  return (
    <Form
      name="basic"
      initialValues={{ ...item, selectedTag: [item?.tags[0]?.id] }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        name="id"
        hidden
      />

      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please add a name!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
      >
        <Input />
      </Form.Item>

      <TagSelector tagOptions={tagOptions} ingredientId={item.id} />

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
});