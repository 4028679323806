import React from 'react';
import { Outlet } from "react-router-dom";
import { Layout, Menu } from 'antd';
import { isMobile } from 'react-device-detect';

export default function SubLayout({ subMenu, defaultSelectedKeys }) {
  const { Content, Sider } = Layout;

  return (
    <>
      {subMenu && (
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            style={{ height: '100%', borderRight: 0 }}
            defaultSelectedKeys={defaultSelectedKeys}
          >
            {subMenu}
          </Menu>
        </Sider>
      )}
      <Layout style={{ padding: isMobile ? '0' : '0 24px 24px' }}>
        <Content
          className="site-layout-background"
          style={{
            padding: isMobile ? 0 : 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </>
  );
}