import React, { useState } from 'react';
import { Button } from 'antd';
import { getFamily, setFamily } from '../../../../helpers/localstorage';
import { Picker } from 'antd-mobile';
import { useFetchUserFamilies } from '../../../../api/nomnom/UserProfile/useFetchUserFamilies';
import { useNavigate } from 'react-router';
import useTheme from '../../../../hooks/useTheme';

export default function FamilyPicker() {
  const [darkMode] = useTheme();
  const families = useFetchUserFamilies();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const onClick = (family) => {
    // eslint-disable-next-line
    setFamily(family[0, 0]);
    setVisible(false);
    navigate(0);
  };

  const items = [families?.data
    ?.filter((family) => family.id !== getFamily()?.id)
    .map((family) => {
      return {
        label: family.name,
        value: family,
        key: family.id
      };
    }) ?? [], []];

  const onButtonClick = async () => {
    setVisible(true);
  };

  if (!items || (items[0]?.length ?? 0) === 0) {
    return (<div className={darkMode ? "header-user-account-mobile" : ""}>
      {getFamily()?.name}
    </div>
    );
  }
  else {
    return (
      <>
        <Button
          type="dashed"
          className={darkMode ? "header-user-account-mobile" : ""}
          style={{ cursor: "pointer" }}
          onClick={onButtonClick}>
          {getFamily()?.name}
        </Button>
        <Picker
          loading={families.isLoading}
          columns={items}
          visible={visible}
          confirmText="Confirm"
          cancelText="Cancel"
          onConfirm={onClick}
          onClose={() => {
            setVisible(false);
          }}
        />
      </>);
  }
}