import React from "react";
import { useFetchIngredientsCreatedReporting } from "../../../api/nomnom/Admin/Reporting/useFetchIngredientReporting";
import { Bar } from "react-chartjs-2";
import Loading from "../../../components/loading";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

export default function ReportCreatedByDateSlice({ entity, dateSlice }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const response = useFetchIngredientsCreatedReporting(entity, dateSlice);

  const options = {
    plugins: {
      title: {
        display: true,
        text: `Created ${entity}`,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (<Loading isLoading={response.isLoading}>
    {response.data && <Bar options={options} data={response.data} />
    }
  </Loading>);
};