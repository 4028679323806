import React, { useState } from "react";
import { Input, Modal, Button, Form } from 'antd';
import { useNavigate } from "react-router";
import { updateFamily } from "../../../api/nomnom/Family/family";
import { EditOutlined } from '@ant-design/icons';

export default function EditFamilyNameModal({ family }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };
  const onFinish = (values) => {
    setLoading(true);
    updateFamily(values).then(() => {
      form.resetFields();
      navigate(0);
      setOpen(false);
    });
  };

  return (
    <>
      <Button title="Edit family name" icon={<EditOutlined />} onClick={showModal} />

      <Modal
        open={open}
        title="Edit"
        destroyOnClose={true}
        onCancel={handleCancel}
        onOk={() => {
          form.validateFields().then((values) => {
            onFinish(values);
          });
        }}
        confirmLoading={loading}
      >
        <Form
          form={form}
          name="editFamily"
          initialValues={family}
        >
          <Form.Item
            name="isPrivate"
            hidden
          />
          <Form.Item
            name="id"
            hidden
          />
          <Form.Item
            label="Family Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Family name is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};