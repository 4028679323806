import React, { useState } from "react";
import { Input, Modal, Button, Form } from 'antd';
import { useNavigate } from "react-router";
import { addUserToFamily } from "../../../api/nomnom/Family/administrator";
import { PlusOutlined } from '@ant-design/icons';

export default function AddUserToFamilyModal({ family }) {
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };
  const onFinish = (values) => {
    setLoading(true);
    addUserToFamily(values.id, values.email).then(() => {
      form.resetFields();
      setOpen(false);
      setLoading(false);
      navigate(0);
    });
  };

  return (
    <>
      <Button
        title="Add User to Family"
        icon={<PlusOutlined />}
        disabled={family.isPrivate}
        onClick={showModal} />
      <Modal
        open={open}
        title="Add User"
        destroyOnClose={true}
        onCancel={handleCancel}
        onOk={() => {
          form.validateFields().then((values) => {
            onFinish(values);
          });
        }}
        confirmLoading={loading}
      >
        <Form
          form={form}
          name="addUserToFamily"
          initialValues={family}
        >
          <Form.Item
            name="id"
            hidden
          />
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Email is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};