import { notification } from 'antd';
import React, { useState, useEffect } from "react";
import { search as searchIngredients } from '../../../api/nomnom/Family/ingredient';
import { getFamilyId, getShoppingListId } from "../../../helpers/localstorage";
import { useNavigate } from 'react-router-dom';
import { List, Space, SwipeAction, InfiniteScroll } from 'antd-mobile';
import { InfiniteScrollContent } from '../../../components/loading/InfiniteScrollContent';
import EditableTagGroup from '../../../components/tags/EditableTagGroup';
import { useSignalR } from '../../../contexts/SignalRContext';
import { Puller } from '../../../components/Puller';
import Loading from '../../../components/loading';

export const DisplayMobile = React.memo(() => {
  const navigate = useNavigate();
  const { postCommand } = useSignalR();
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({
    items: [],
    currentPage: 0,
    pageSize: 20,
    hasNext: true,
    isLoading: true
  });

  const [isLoading, setLoading] = useState(true);

  const onScroll = async () => {
    const resp = await searchIngredients(getFamilyId(), data.currentPage + 1, data.pageSize, null);
    setData({ ...resp, items: [...data.items, ...resp.items] });
  };

  useEffect(() => {
    onScroll().then(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  const openNotification = (ingredientName) => {
    api.info({
      message: `${ingredientName} successfully add to list.`,
      placement: 'bottomRight',
    });
  };

  const onAddToShoppingList = (ingredientId, ingredientName) => {
    const ingredient = { id: 0, ingredientId: ingredientId, quantity: 1 };
    postCommand("UpdateShoppingListIngredientAsync", getFamilyId(), getShoppingListId(), ingredient);
    openNotification(ingredientName);
  };

  const rightActions = [
    {
      key: 'add',
      text: 'Add',
      color: 'gray',
    },
    {
      key: 'edit',
      text: 'Edit',
      color: 'gray',
    }
  ];

  const onListAction = (action, item) => {
    switch (action.key) {
    case "add":
      onAddToShoppingList(item.id, item.name);
      break;
    case "edit":
      navigate(`../${item.id}`);
      break;
    default:
      break;
    };
  };


  return (
    <>
      {contextHolder}
      <Loading isLoading={isLoading}>
        <Puller>
          <List
            bordered={true}>
            {data?.items.map((item) => (
              <SwipeAction
                key={item.key}
                rightActions={rightActions}
                onAction={(action) => onListAction(action, item)}
              >
                <List.Item key={item.key}>
                  <Space>
                    {item.name}
                    <EditableTagGroup ingredientId={item.id} tags={item.tags} />
                  </Space>
                </List.Item>
              </SwipeAction>
            ))}
          </List>
          <InfiniteScroll loadMore={onScroll} threshold={50} hasMore={data.hasNext}>
            <InfiniteScrollContent hasMore={data.hasNext} />
          </InfiniteScroll>
        </Puller>
      </Loading>
    </>
  );
});