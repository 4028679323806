import React from "react";
import { Form, Space } from "antd";
import { Selector } from "antd-mobile";
import { CreateIngredientTagModal } from './CreateIngredientTagModal';
export default function TagSelector({ tagOptions, ingredientId, props }) {
  const sortObjectsByName = (objectsArray) => {
    return objectsArray.sort(function (a, b) {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  };

  return (
    tagOptions && <Space><Form.Item
      label="Category"
      name="selectedTag">
      <Selector
        options={sortObjectsByName(tagOptions).map(item => { return { label: item.name, value: item.id }; })}
      />
    </Form.Item>
    {ingredientId && <Form.Item>
      <CreateIngredientTagModal ingredientId={ingredientId} />
    </Form.Item>
    }
    </Space>
  );
};