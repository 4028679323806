import React from "react";
import List from './List';
import { Row, Space } from 'antd';
import CreateFamilyModal from "../CreateFamilyModal";
import { useFetchUserFamilies } from "../../../api/nomnom/UserProfile/useFetchUserFamilies";
import Loading from "../../../components/loading";

export default function Families() {
  const families = useFetchUserFamilies();

  return (
    <Loading isLoading={families.isLoading}>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Row>
          <Space>
            <CreateFamilyModal />
          </Space>
        </Row>
        <List data={families.data} />
      </Space>
    </Loading>
  );
};