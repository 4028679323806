import { React } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isInRoles } from '../helpers/jwt';
import { getJwtFromStorage } from '../helpers/localstorage';
import { basePath } from '../helpers/urlHelper';

const ProtectedRoute = ({ component, allowedRoles }) => {
  const token = getJwtFromStorage();
  const location = useLocation();

  if (!token || !isInRoles(allowedRoles)) {
    return <Navigate to={`${basePath()}/login`} replace state={{ from: location }} />;
  }
  return component;
};

export default ProtectedRoute;