import "./Navigation.css";
import React from "react";
import { Tag, Space } from "antd";
import Loading from '../../loading';
import { useFetchArtifactVersions } from "../../../api/nomnom/Admin/useFetchArtifactVersions";

export default function SystemInfo() {
  const response = useFetchArtifactVersions();

  return (<Loading isLoading={response.isLoading}>
    {response.data && <Space direction="vertical">
      <Tag color="purple">web:{response.data.web}</Tag>
      <Tag color="geekblue">api:{response.data.api}</Tag>
    </Space>
    }
  </Loading>);
};