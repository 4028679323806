import React from 'react';
import { Link } from 'react-router-dom';
import SubLayout from '../../components/layouts/SubLayout';
import { Menu } from 'antd';
import { basePath } from '../../helpers/urlHelper';

export default function Index() {
  const subMenu = (<>
    <Menu.Item key="reports">
      <Link to={basePath("admin/reports")}>
        Reports
      </Link>
    </Menu.Item>
    <Menu.Item key="configuration">
      <Link to={basePath("admin/configuration")}>
        Configuration
      </Link>
    </Menu.Item>
    <Menu.Item key="families">
      <Link to={basePath("admin/families")}>
        Families
      </Link>
    </Menu.Item>
    <Menu.Item key="units-of-measure">
      <Link to={basePath("admin/units-of-measure")}>
        Units of Measure
      </Link>
    </Menu.Item>
    <Menu.Item key="image-job-types">
      <Link to={basePath("admin/image-job-types")}>
        Image Job Types
      </Link>
    </Menu.Item>
    <Menu.Item key="image-jobs">
      <Link to={basePath("admin/image-jobs")}>
        Image Jobs
      </Link>
    </Menu.Item>
  </>);
  return (
    <>
      <SubLayout
        subMenu={subMenu}
        title="Admin"
        defaultSelectedKeys={['reports']}
      />
    </>
  );
};